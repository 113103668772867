type PluralizeFunction = (num: number, word: string) => string

const _pluralize = (num: number, word: string, plural: string): string =>
  Math.abs(num) === 1 ? word : plural

const isObject = (val: unknown): val is Record<string, string> =>
  typeof val === 'object' && val !== null

export const pluralize: (
  val: number | Record<string, string>,
  word: string,
  plural?: string,
) => string | PluralizeFunction = (val, word, plural = `${word}s`) => {
  if (isObject(val)) {
    return (num: number, word: string) =>
      _pluralize(num, word, val[word] || `${word}s`)
  }

  return _pluralize(val, word, plural)
}
