import React from 'react'

import {
  ActionId,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useMatches,
} from 'kbar'
import { ActionImpl } from 'kbar/lib/action'
import { useRouter } from 'next/router'
import { scopes } from 'scopes'
import { useSettings } from 'store/useSettings'

import { useAuth } from '../../../store/useAuth'
import { useGroupSearchActions } from './useGroupSearchActions'
import { useLeadSearchAction } from './useLeadSearchActions'
import { useMemberSearchAction } from './useMemberSearchActions'
import useThemeActions from './useThemeActions'

export const Kbar = ({ children }): JSX.Element => {
  const router = useRouter()

  const user = useAuth(state => state.user)

  const { level } = user

  const initialActions = React.useMemo(() => {
    const actions = [
      {
        role: [scopes.General.Dashboard.VIEW],
        id: 'dashboardAction',
        name: 'Dashboard',
        //shortcut: ['d'],
        keywords: 'back',
        section: 'Navigation',
        perform: () => router.push('/dashboard'),
        //   icon: <AccessTimeOutlinedIcon />
        //   subtitle: 'Subtitles can help add more context.'
      },

      {
        role: [scopes.General.Leads.VIEW],
        id: 'leadsAction',
        name: 'Leads',
        //   shortcut: ['o', ''],
        keywords: 'leads',
        section: 'Navigation',
        perform: () => router.push(`/leads`),
      },
      {
        role: [scopes.General.Account.MANAGE],
        id: 'accountAction',
        name: 'Accounts',
        keywords: 'accounts',
        section: 'Navigation',
        perform: () => router.push(`/account?tab=general`),
      },
      {
        role: [scopes.Management.Onboard.CREATE_WIZARD],
        //shortcut: ['o'],
        id: 'onboardAction',
        name: 'Onboard',
        keywords: 'onboard',
        section: 'Navigation',
        perform: () => router.push(`/onboard`),
      },
      {
        role: [scopes.Management.Members.VIEW],
        id: 'memberAction',
        name: 'Members',
        keywords: 'members',
        section: 'Navigation',
        perform: () => router.push(`/members`),
      },
      {
        role: [scopes.Management.Brands.VIEW],
        id: 'brandingAction',
        name: 'Brandings',
        keywords: 'brandings',
        section: 'Navigation',
        perform: () => router.push(`/brandings`),
      },
      {
        role: [scopes.Management.Groups.VIEW],
        id: 'groupAction',
        name: 'Groups',
        keywords: 'groups',
        section: 'Navigation',
        perform: () => router.push(`/groups`),
      },
    ]

    return actions.filter(item =>
      item.role.some(scope => user?.scopes?.includes(scope)),
    )
  }, [])

  return (
    <>
      <KBarProvider
        options={{
          enableHistory: true,
        }}
        actions={initialActions}
      >
        <CommandBar />
        {children}
      </KBarProvider>
    </>
  )
}

const darkBackground = '#0B0F19'

const lightBackground = '#fff'

const textOnDark = '#EDF2F7'

const textOnLight = '#121828'

const secondaryTextOnDark = '#A0AEC0'

const secondaryTextOnLight = '#65748B'

const darkDivider = '#2D3748'
const lightDivider = '#E6E8F0'

function CommandBar() {
  const user = useAuth(state => state.user)

  const settings = useSettings()

  const isDark = settings.theme === 'dark'

  const searchStyle = React.useMemo(() => {
    return {
      padding: '1.2rem',
      fontSize: '1rem',
      width: '100%',
      boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
      outline: 'none',
      border: 'none',
      borderBottom: `1px solid ${isDark ? darkDivider : lightDivider}`,
      background: isDark ? darkBackground : lightBackground,
      color: isDark ? textOnDark : textOnLight,
    }
  }, [settings.theme])

  const backgroundStyle = {
    zIndex: 9999,
    backgroundColor: 'rgba(1,4,9,0.6)',
  }
  const animatorStyle = React.useMemo(() => {
    return {
      maxWidth: '600px',
      width: '100%',
      border: `1px solid ${isDark ? darkDivider : lightDivider}`,
      background: isDark ? darkBackground : lightBackground,
      color: isDark ? textOnDark : textOnLight,
      borderRadius: '5px',
      overflow: 'hidden',
    }
  }, [settings.theme])

  useLeadSearchAction()
  if (
    user?.scopes.includes(scopes.Management.Roles.IS_BRAND_ADMIN) ||
    user?.scopes.includes(scopes.Management.Roles.IS_SUPER_ADMIN)
  ) {
    useMemberSearchAction()
    useGroupSearchActions()
  }

  useThemeActions()
  return (
    <KBarPortal data-onborda="kbar-positioner">
      <KBarPositioner style={backgroundStyle}>
        <KBarAnimator style={animatorStyle}>
          <div data-onborda="kbar-results">
            <KBarSearch style={searchStyle} data-onborda="kbar-search" />
            <RenderResults />
          </div>
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  )
}

function RenderResults() {
  const { results, rootActionId } = useMatches()

  const settings = useSettings()

  const isDark = settings.theme === 'dark'

  const groupNameStyle = React.useMemo(() => {
    return {
      padding: '8px 16px',
      fontSize: '0.66rem',
      textTransform: 'uppercase' as const,
      //opacity: 0.8,
      color: isDark ? secondaryTextOnDark : secondaryTextOnLight,
      borderBottom: `1px solid ${isDark ? darkDivider : lightDivider}`,
    }
  }, [settings.theme])
  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  )
}

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl
      active: boolean
      currentRootActionId: ActionId
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const settings = useSettings()

    const isDark = settings.theme === 'dark'

    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors
      const index = action.ancestors.findIndex(
        ancestor => ancestor.id === currentRootActionId,
      )
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    return (
      <div
        ref={ref}
        style={{
          padding: '12px 16px',
          background: active
            ? isDark
              ? darkDivider
              : lightDivider
            : 'transparent',
          color: active ? (isDark ? textOnDark : textOnLight) : 'inherit',
          //   borderLeft: `2px solid ${
          //     active ? 'var(--foreground)' : 'transparent'
          //   }`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          fontSize: '0.96rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          {action.icon && action.icon}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map(ancestor => (
                  <React.Fragment key={ancestor.id}>
                    {ancestor.showBreadcrumbs !== true && (
                      <>
                        <span
                          style={{
                            opacity: 0.5,
                            marginRight: 8,
                          }}
                        >
                          {ancestor.name}
                        </span>
                        <span
                          style={{
                            marginRight: 8,
                          }}
                        >
                          &rsaquo;
                        </span>
                      </>
                    )}
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span style={{ fontSize: 14 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: '4px',
            }}
          >
            {action.shortcut.map(sc => (
              <kbd
                key={sc}
                style={{
                  padding: '4px 6px',
                  background: 'rgba(0 0 0 / .1)',
                  borderRadius: '4px',
                  fontSize: 14,
                }}
              >
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    )
  },
)
