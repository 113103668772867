import axios from 'axios'
import { useKBar, useRegisterActions, Priority } from 'kbar'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import api from 'utils/api'
import { Avatar, Box, Chip, Typography } from '@mui/material'
import { getInitials } from 'utils/get-initials'

import { getGroupMemberProfileImageUrl } from 'tenant'

const searchId = 'groupSearch'

const getGroupIcon = group => {
  const { displayInfo, id } = group
  const avatarImage = displayInfo?.company_image
    ? getGroupMemberProfileImageUrl({
        groupId: id,
        width: 100,
        profileImage: displayInfo?.company_image,
      })
    : ''
  return (
    <Avatar
      sx={{
        border: '1px solid',
        borderColor: theme => theme.palette.divider,
      }}
      src={avatarImage}
      variant="rounded"
    >
      {getInitials(displayInfo.name)}
    </Avatar>
  )
}

const getGroupSubtitle = group => {
  const { displayInfo, role, id } = group
  const { email } = displayInfo
  const label = `${role}: ${id}`

  return (
    <Typography color="textSecondary" variant="body2">
      {email}
    </Typography>
  )
}

const getGroupName = group => {
  const { displayInfo, role, id } = group
  const { email, name } = displayInfo
  const label = `${role}: ${id}`
  return (
    <Box sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}>
      <Typography variant="subtitle2">{name}</Typography>
      <Chip
        label={label}
        size="small"
        sx={{
          fontSize: '11px',
          height: '100%',
          backgroundColor: theme => theme.palette.primary.main,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme => theme.palette.neutral[500],
          borderRadius: '4px',
          '& .MuiChip-label': {
            px: '4px',
            py: 0,
          },
        }}
      />
    </Box>
  )
}

export function useGroupSearchActions() {
  const { searchQuery } = useKBar(state => ({
    searchQuery: state.searchQuery,
  }))
  const [groups, setGroups] = useState([])
  const router = useRouter()

  useEffect(() => {
    let CancelToken = axios.CancelToken
    let source = CancelToken.source()
    const handleSearch = async () => {
      try {
        const response = await api.get(
          `/groups/all?fields=id,displayInfo,brandingId,integrationBehavior,forceRegId,staging,role&pagination%5Bpage%5D=0&pagination%5Blimit%5D=10&searchText=${searchQuery}`,
          {
            cancelToken: source.token,
          },
        )

        setGroups(response.data.data || [])
      } catch (err) {
        console.error(err)
        setGroups([])
      }
    }

    if (searchQuery) {
      handleSearch()
    } else {
      setGroups([])
    }

    return () => {
      source.cancel('Cancelled due to stale request')
    }
  }, [searchQuery])

  const createAction = group => ({
    id: `group-${group.id}`,
    parent: searchId,
    name: getGroupName(group),
    subtitle: getGroupSubtitle(group),
    section: 'Groups',
    keywords: [group.id, group.displayInfo.name, group.displayInfo.email],
    perform: () => {
      router.push(`/groups/${group.id}?tab=details`)
    },
    icon: getGroupIcon(group),
  })

  const rootSearchAction = useMemo(
    () => ({
      id: searchId,
      shortcut: ['g'],
      keywords: 'find search groups',
      section: 'Search',
      priority: Priority.HIGH,
      name: 'Search Groups',
      hideBreadcrumbs: true,
    }),
    [],
  )

  const actions = useMemo(() => {
    if (!groups.length) return [rootSearchAction]
    return [rootSearchAction, ...groups.map(group => createAction(group))]
  }, [groups, rootSearchAction])

  useRegisterActions(actions, [actions])
}
