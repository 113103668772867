export const convertSecondsToReadableString = (
  totalSeconds: number,
  abbrivate: boolean = false,
  remove: string[] = [],
) => {
  if (totalSeconds < 0) totalSeconds = -totalSeconds

  let time = {}
  if (abbrivate) {
    time = {
      day: Math.floor(totalSeconds / (3600 * 24)),
      hr: Math.floor((totalSeconds % (3600 * 24)) / 3600),
      min: Math.floor((totalSeconds % 3600) / 60),
      sec: Math.floor(totalSeconds % 60),
    }
  } else {
    time = {
      day: Math.floor(totalSeconds / (3600 * 24)),
      hour: Math.floor((totalSeconds % (3600 * 24)) / 3600),
      minute: Math.floor((totalSeconds % 3600) / 60),
      second: Math.floor(totalSeconds % 60),
    }
  }

  // remove keys from time object if they are in the remove array
  remove?.forEach(key => {
    delete time[key]
  })

  return Object.entries(time)
    .filter(val => val[1] !== 0)
    .map(val => val[1] + ' ' + (val[1] !== 1 ? val[0] + 's' : val[0]))
    .join(', ')
}
