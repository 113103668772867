import { useRegisterActions } from 'kbar'
import toast from 'react-hot-toast'

import { useSettings } from '../../../store/useSettings'

function Toast({ title, action, buttonText }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
      <span style={{ fontSize: 14 }}>{title}</span>
      <button
        onClick={action}
        style={{
          background: '#fff',
          border: 'none',
          boxShadow: '0 0 0 1px #000',
          padding: '4px 8px',
          cursor: 'pointer',
          borderRadius: 4,
          fontSize: 14,
        }}
      >
        {buttonText}
      </button>
    </div>
  )
}

export default function useThemeActions() {
  const { settings, saveSettings } = useSettings()
  const value = useSettings()

  useRegisterActions([
    {
      id: 'theme',
      name: 'Change theme…',
      keywords: 'interface color dark light',
      section: 'Preferences',
    },
    {
      id: 'darkTheme',
      name: 'Dark',
      keywords: 'dark theme',
      section: '',
      perform: actionImpl => {
        saveSettings({
          ...settings,
          theme: 'dark',
        })
        toast(
          <div>
            <Toast
              title="Dark theme enabled"
              buttonText="Undo"
              action={() => {
                actionImpl.command.history.undo()
                toast.dismiss('dark')
                saveSettings({
                  ...settings,
                  theme: 'light',
                })
                toast(
                  <Toast
                    title="Dark theme undone"
                    buttonText="Redo"
                    action={() => {
                      saveSettings({
                        ...settings,
                        theme: 'dark',
                      })
                      actionImpl.command.history.redo()
                      toast.dismiss('dark-undo')
                    }}
                  />,
                  {
                    id: 'dark-undo',
                  },
                )
              }}
            />
          </div>,
          {
            id: 'dark',
          },
        )
        return () => {
          // doc.removeAttribute(attribute);
        }
      },
      parent: 'theme',
    },
    {
      id: 'lightTheme',
      name: 'Light',
      keywords: 'light theme',
      section: '',
      perform: actionImpl => {
        saveSettings({
          ...settings,
          theme: 'light',
        })

        toast(
          <div>
            <Toast
              title="Light theme enabled"
              buttonText="Undo"
              action={() => {
                actionImpl.command.history.undo()
                toast.dismiss('light')
                saveSettings({
                  ...settings,
                  theme: 'dark',
                })
                toast(
                  <Toast
                    title="Light theme undone"
                    buttonText="Redo"
                    action={() => {
                      saveSettings({
                        ...settings,
                        theme: 'light',
                      })
                      toast.dismiss('light-undo')
                    }}
                  />,
                  {
                    id: 'light-undo',
                  },
                )
              }}
            />
          </div>,
          {
            id: 'light',
          },
        )

        return () => {
          if (value.theme === 'dark');
        }
      },
      parent: 'theme',
    },
  ])
}
