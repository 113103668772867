export enum NOTES {
  CREATE = 'General.Notes.CREATE',
  DELETE = 'General.Notes.DELETE',
  COMMENT = 'General.Notes.COMMENT',
  COMMENT_DELETE = 'General.Notes.COMMENT_DELETE',
}

type Notes = {
  CREATE: NOTES.CREATE
  DELETE: NOTES.DELETE
  COMMENT: NOTES.COMMENT
  COMMENT_DELETE: NOTES.COMMENT_DELETE
}

export enum INQUIRY {
  ALL = 'General.Inquiry.ALL',
  LEAD = 'General.Inquiry.LEAD',
}

type Inquiry = {
  ALL: INQUIRY.ALL
  LEAD: INQUIRY.LEAD
}

export enum SHOWINGREQUEST {
  ALL = 'General.ShowingRequest.ALL',
  LEAD = 'General.ShowingRequest.LEAD',
}

type ShowingRequest = {
  ALL: SHOWINGREQUEST.ALL
  LEAD: SHOWINGREQUEST.LEAD
}

export enum PROPERTY {
  ALL = 'General.Property.ALL',
  LEAD = 'General.Property.LEAD',
}

type Property = {
  ALL: PROPERTY.ALL
  LEAD: PROPERTY.LEAD
}

export enum SAVEDSEARCH {
  ALL = 'General.SavedSearch.ALL',
  LEAD = 'General.SavedSearch.LEAD',
}

type SavedSearch = {
  ALL: SAVEDSEARCH.ALL
  LEAD: SAVEDSEARCH.LEAD
}

export enum LEADS {
  VIEW_REQUESTS = 'General.Leads.VIEW_REQUESTS',
  VIEW_SEARCHES = 'General.Leads.VIEW_SEARCHES',
  VIEW_PROPERTIES = 'General.Leads.VIEW_PROPERTIES',
  ALL = 'General.Leads.ALL',
  REASSIGN = 'General.Leads.REASSIGN',
  DELETE = 'General.Leads.DELETE',
  UPDATE = 'General.Leads.UPDATE',
  OMNISEARCH = 'General.Leads.OMNISEARCH',
  VIEW = 'General.Leads.VIEW',
  WARM_TRANSFER = 'General.Leads.WARM_TRANSFER',
  FILTER_BY_LEAD_SOURCES = 'General.Leads.FILTER_BY_LEAD_SOURCES',
  FILTER_BY_BRANDS = 'General.Leads.FILTER_BY_BRANDS',
  FILTER_BY_BRAND_GROUPS = 'General.Leads.FILTER_BY_BRAND_GROUPS',
}

type Leads = {
  VIEW_REQUESTS: LEADS.VIEW_REQUESTS
  VIEW_SEARCHES: LEADS.VIEW_SEARCHES
  VIEW_PROPERTIES: LEADS.VIEW_PROPERTIES
  ALL: LEADS.ALL
  REASSIGN: LEADS.REASSIGN
  DELETE: LEADS.DELETE
  UPDATE: LEADS.UPDATE
  OMNI_SEARCH: LEADS.OMNISEARCH
  VIEW: LEADS.VIEW
  WARM_TRANSFER: LEADS.WARM_TRANSFER
  FILTER_BY_LEAD_SOURCES: LEADS.FILTER_BY_LEAD_SOURCES
  FILTER_BY_BRANDS: LEADS.FILTER_BY_BRANDS
  FILTER_BY_BRAND_GROUPS: LEADS.FILTER_BY_BRAND_GROUPS
}

export enum ACCOUNT {
  MANAGE = 'General.Account.MANAGE',
  VIEW_LOGIN_HISTORY = 'General.Account.VIEW_LOGIN_HISTORY',
  CAN_DELETE_OWN_ACCOUNT = 'General.Account.CAN_DELETE_OWN_ACCOUNT',
}

type Account = {
  MANAGE: ACCOUNT.MANAGE
  VIEW_LOGIN_HISTORY: ACCOUNT.VIEW_LOGIN_HISTORY
  CAN_DELETE_OWN_ACCOUNT: ACCOUNT.CAN_DELETE_OWN_ACCOUNT
}

export enum DASHBOARD {
  VIEW = 'General.Dashboard.VIEW',
}
type Dashboard = {
  VIEW: DASHBOARD.VIEW
}

export interface General {
  Dashboard: Dashboard
  Leads: Leads
  Account: Account
  Inquiry: Inquiry
  Property: Property
  SavedSearch: SavedSearch
  ShowingRequest: ShowingRequest
  Notes: Notes
}
