import React from 'react';

type RealtyLogoProps = {
    theme: 'light' | 'dark';
    logoWidth?: string;
};

export const RealtyLogo: React.FC<RealtyLogoProps> = ({ theme, logoWidth = '2rem' }) => {
    const color = theme === 'light' ? '#0E7AB1' : '#fff';

    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={logoWidth}
            viewBox="0 0 180.000000 180.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
                fill={color}
                stroke="none"
            >
                <path
                    d="M0 900 l0 -900 900 0 900 0 0 900 0 900 -900 0 -900 0 0 -900z m1315
            411 c84 -38 104 -88 105 -260 0 -150 -17 -198 -79 -228 l-37 -18 37 -17 c56
            -25 70 -61 76 -199 l6 -119 -136 0 -135 0 -3 84 c-6 132 7 126 -281 126 l-238
            0 0 -105 0 -105 -135 0 -135 0 0 430 0 430 458 0 c422 0 460 -2 497 -19z"
                />
                <path
                    d="M630 1011 l0 -101 243 0 c287 0 280 -2 280 101 0 96 8 93 -278 97
            l-245 3 0 -100z"
                />
            </g>
        </svg>
    );
};
