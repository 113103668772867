import { useEffect, useMemo, useState } from 'react'

import axios from 'axios'
import { useKBar, useRegisterActions } from 'kbar'
import { useRouter } from 'next/router'
import api from 'utils/api'

// need to get data

const searchId = 'search2'

const entityTypes = {
  M: 'ma',
  C: 'co',
  I: 'in',
}

export function useMemberSearchAction() {
  const { queryValue } = useKBar(state => ({
    queryValue: state.searchQuery,
  }))
  const [members, setMembers] = useState([])
  const router = useRouter()

  useEffect(() => {
    let CancelToken = axios.CancelToken
    let source = CancelToken.source()
    const handleSearch = async () => {
      try {
        const response = await api.get(
          `/members?fields=id,firstName,lastName,email,level,jobRole,lastLogin,title,profileImage,brandingId,phoneNumber&pagination%5Bpage%5D=0&pagination%5Blimit%5D=5&showActive=false&searchText=${queryValue}`,
          {
            cancelToken: source.token,
          },
        )

        setMembers(response.data.data)
      } catch (err) {
        console.error(err)
      } finally {
      }
    }

    if (queryValue) {
      handleSearch()
    }
    return () => {
      source.cancel('Cancelled due to stale request')
    }
  }, [queryValue])

  const createAction = lead => {
    return {
      id: lead.id,
      parent: searchId,
      name: `${lead.firstName} ${lead.lastName}`,
      subtitle: `${lead.email}`,
      section: 'Members',
      perform: () => {
        router.push(`/members/${lead.id}?tab=details`)
      },
    }
  }

  const searchActions = useMemo(() => {
    const actions = []
    actions.push({
      id: 'm1',
      parent: searchId,
      name: 'M1',
      keywords: 'maa AB-1 Horses',
      section: entityTypes.M,
      perform: () => {},
    })
    actions.push({
      id: 'm2',
      parent: searchId,
      name: 'M2',
      keywords: 'maa CD-2 Houses',
      section: entityTypes.M,
      perform: () => {},
    })
    actions.push({
      id: 'c1',
      parent: searchId,
      name: 'C1',
      keywords: 'coo XY-1 Joe',
      section: entityTypes.C,
      perform: () => {},
    })
    actions.push({
      id: 'c2',
      parent: searchId,
      name: 'C2',
      keywords: 'coo QR-2 Ava',
      section: entityTypes.C,
      perform: () => {},
    })
    return actions
  }, [])

  const rootSearchAction = useMemo(
    () =>
      searchActions.length
        ? {
            id: searchId,
            name: 'Search Member',
            shortcut: ['m'],
            keywords: 'find',
            section: 'Search',
          }
        : null,
    [searchActions],
  )

  useRegisterActions(
    [rootSearchAction, ...members.map(lead => createAction(lead))],
    [members],
  )
}
