const SURNAME_PREFIXES = [
  'von',
  'van',
  'de',
  'du',
  'di',
  'del',
  'della',
  'der',
  'den',
  'mac',
  'mc',
  "o'",
  'st.',
  'saint',
]
const ROMAN_NUMERALS = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
]
const NAME_SUFFIXES = ['jr', 'sr', 'esq', ...ROMAN_NUMERALS]

export const formatName = (str: string): string => {
  // Early return for empty strings
  if (!str) return ''

  // Convert to lowercase first to handle ALL CAPS
  const words = str.toLowerCase().trim().split(/\s+/)

  return words
    .map((word, index) => {
      // Check if it's a suffix by comparing uppercase versions
      const upperWord = word.replace(/\./g, '').toUpperCase()
      if (NAME_SUFFIXES.some(suffix => suffix.toUpperCase() === upperWord)) {
        return upperWord
      }

      // Handle surname prefixes (von, van, de, etc.)
      if (index > 0 && SURNAME_PREFIXES.includes(word.toLowerCase())) {
        return word.toLowerCase()
      }

      // Handle hyphenated names (Mary-Jane)
      if (word.includes('-')) {
        return word
          .split('-')
          .map(part => capitalizeFirst(part))
          .join('-')
      }

      // Handle Irish names (O'Brien)
      if (word.toLowerCase().startsWith("o'")) {
        return "O'" + capitalizeFirst(word.slice(2))
      }

      // Handle Scottish/Irish names (McDonald)
      if (word.toLowerCase().startsWith('mc')) {
        return 'Mc' + capitalizeFirst(word.slice(2))
      }

      // Default case: capitalize first letter
      return capitalizeFirst(word)
    })
    .join(' ')
}

const capitalizeFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
