export const getPropertyImage = (
  listingId: number,
  photoCount: number,
  source: string,
  lat: number,
  lng: number,
  ext: string = 'jpg',
) => {
  if (photoCount > 0) {
    return `https://imagecdn.realty.com/photos/${source}/${listingId}/1.${ext}`
  }
  console.log(
    `https://imagecdn.realty.com/photos/${source}/${listingId}/gsView.${ext}`,
  )
  return `https://imagecdn.realty.com/photos/${source}/${listingId}/gsview.${ext}`
}
