import * as yup from 'yup';

export const getValidationSchema = (fi: any[]) => {
  const flattenFields = (fields: any[]): any[] => {
    if (!Array.isArray(fields)) {
      console.error('getInitialValues: fields is not an array:', fields);
      return [];
    }
    return fields.reduce((prev, current) => {
      if (current.fields) {
        return [...prev, current, ...flattenFields(current.fields)];
      }
      return [...prev, current];
    }, []);
  };

  const flattenedFields = flattenFields(fi);

  const schema = flattenedFields?.reduce((schema: any, field: any) => {
    const { id, validationType, validationTypeError, validations = [], nullable = false } = field;
    const isObject = id?.indexOf('.') >= 0;

    if (!yup[validationType]) {
      return schema;
    }

    let validator = yup[validationType]();

    if (nullable) {
      validator = validator.nullable();
    }

    validations.forEach(validation => {
      const { params, type } = validation;

      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });

    if (!isObject) {
      return schema.concat(yup.object().shape({ [id]: validator }));
    }

    const reversePath = id?.split('.').reverse();
    const currNestedObject = reversePath.slice(1).reduce(
      (yupObj, path, index, source) => {
        if (!isNaN(path)) {
          return { array: yup.array().of(yup.object().shape(yupObj)) };
        }
        if (yupObj.array) {
          return { [path]: yupObj.array };
        }
        return { [path]: yup.object().shape(yupObj) };
      },
      { [reversePath[0]]: validator },
    );

    const newSchema = yup.object().shape(currNestedObject);
    return schema.concat(newSchema);
  }, yup.object().shape({}));

  return schema;
};
