var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/scopes/scopes.ts
var config = {
  General: {
    Leads: {
      BRAND: "General.Leads.BRAND" /* BRAND */,
      CREATE: "General.Leads.CREATE" /* CREATE */,
      DELETE: "General.Leads.DELETE" /* DELETE */,
      LEAD_SOURCE_FILTER: "General.Leads.LEAD_SOURCE_FILTER" /* LEAD_SOURCE_FILTER */,
      NOTES: "General.Leads.NOTES" /* NOTES */,
      REASSIGN: "General.Leads.REASSIGN" /* REASSIGN */,
      SUPER: "General.Leads.SUPER" /* SUPER */,
      UPDATE: "General.Leads.UPDATE" /* UPDATE */,
      VIEW: "General.Leads.VIEW" /* VIEW */,
      VIEW_HIDDEN: "General.Leads.VIEW_HIDDEN" /* VIEW_HIDDEN */,
      WARM_TRANSFER: "General.Leads.WARM_TRANSFER" /* WARM_TRANSFER */,
      STAGE_CHANGE: "General.Leads.STAGE_CHANGE" /* STAGE_CHANGE */,
      LEAD_ASSIST: "General.Leads.LEAD_ASSIST" /* LEAD_ASSIST */,
      ONLY_PRIMARY_GROUP: "General.Leads.ONLY_PRIMARY_GROUP" /* ONLY_PRIMARY_GROUP */,
      RESTRICTED_LEAD_SOURCE_ACCESS: "General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS" /* RESTRICTED_LEAD_SOURCE_ACCESS */,
      HIDE_OWNER_INFO: "General.Leads.HIDE_OWNER_INFO" /* HIDE_OWNER_INFO */
    }
  },
  Global: {
    Settings: {
      ACCESS: "General.Settings.ACCESS" /* ACCESS */
    }
  },
  Management: {
    Tasks: {
      SUPER: "Management.Tasks.SUPER" /* SUPER */,
      BRAND: "Management.Tasks.BRAND" /* BRAND */,
      VIEW: "Management.Tasks.VIEW" /* VIEW */
    },
    Roles: {
      CREATE: "Management.Roles.CREATE" /* CREATE */,
      UPDATE: "Management.Roles.UPDATE" /* UPDATE */,
      DELETE: "Management.Roles.DELETE" /* DELETE */,
      LIST: "Management.Roles.LIST" /* LIST */,
      IS_BRAND_ADMIN: "Management.Roles.IS_BRAND_ADMIN" /* IS_BRAND_ADMIN */,
      IS_SUPER_ADMIN: "Management.Roles.IS_SUPER_ADMIN" /* IS_SUPER_ADMIN */
    },
    Campaigns: {
      VIEW: "Management.Campaigns.VIEW" /* VIEW */,
      CREATE_ASSIGNMENT: "Management.Campaigns.CREATE_ASSIGNMENT" /* CREATE_ASSIGNMENT */,
      UPDATE_ASSIGNMENT: "Management.Campaigns.UPDATE_ASSIGNMENT" /* UPDATE_ASSIGNMENT */,
      ASSIGNED: "Management.Campaigns.ASSIGNED" /* ASSIGNED */,
      DELETE_ASSIGNED: "Management.Campaigns.DELETE_ASSIGNED" /* DELETE_ASSIGNED */,
      ALL: "Management.Campaigns.ALL" /* ALL */,
      BRAND: "Management.Campaigns.BRAND" /* BRAND */,
      SUPER: "Management.Campaigns.SUPER" /* SUPER */
    },
    Brands: {
      CREATE: "Management.Brands.CREATE" /* CREATE */,
      DELETE: "Management.Brands.DELETE" /* DELETE */,
      UPDATE: "Management.Brands.UPDATE" /* UPDATE */,
      VIEW: "Management.Brands.VIEW" /* VIEW */,
      ZOHO_SYNC: "Management.Brands.ZOHO_SYNC" /* ZOHO_SYNC */,
      ALL: "Management.Brands.ALL" /* ALL */,
      LOCATION_ADD: "Management.Brands.LOCATION_ADD" /* LOCATION_ADD */,
      LOCATION_REMOVE: "Management.Brands.LOCATION_REMOVE" /* LOCATION_REMOVE */,
      LOCATION_ASSIGNED: "Management.Brands.LOCATION_ASSIGNED" /* LOCATION_ASSIGNED */,
      BILLING: "Management.Brands.BILLING" /* BILLING */
    },
    Groups: {
      VIEW: "Management.Groups.VIEW" /* VIEW */,
      CREATE: "Management.Groups.CREATE" /* CREATE */,
      DELETE: "Management.Groups.DELETE" /* DELETE */,
      UPDATE: "Management.Groups.UPDATE" /* UPDATE */,
      ALL: "Management.Groups.ALL" /* ALL */,
      AVAILABLE: "Management.Groups.AVAILABLE" /* AVAILABLE */,
      IN_BRAND: "Management.Groups.IN_BRAND" /* IN_BRAND */,
      ASSIGN_LOCATION: "Management.Groups.ASSIGN_LOCATION" /* ASSIGN_LOCATION */,
      UNASSIGN_LOCATION: "Management.Groups.UNASSIGN_LOCATION" /* UNASSIGN_LOCATION */,
      LOCATION_ASSIGNED: "Management.Groups.LOCATION_ASSIGNED" /* LOCATION_ASSIGNED */,
      SEND_TEST_LEAD: "Management.Groups.SEND_TEST_LEAD" /* SEND_TEST_LEAD */,
      PING_ZIP: "Management.Groups.PING_ZIPS" /* PING_ZIPS */,
      PING_STATS: "Management.Groups.PING_STATS" /* PING_STATS */,
      DELETE_PING_ZIP: "Management.Groups.DELETE_PING_ZIP" /* DELETE_PING_ZIP */,
      RESET_PING_ZIP: "Management.Groups.RESET_PING_ZIP" /* RESET_PING_ZIP */,
      ROUTING: "Managment.Groups.ROUTING" /* ROUTING */,
      ADD_UPDATE_TRACKDRIVE: "Management.Groups.ADD_UPDATE_TRACKDRIVE" /* ADD_UPDATE_TRACKDRIVE */,
      BRAND: "Management.Groups.BRAND" /* BRAND */,
      SUPER: "Management.Groups.SUPER" /* SUPER */,
      LEAD: "Management.Groups.LEAD" /* LEAD */,
      LEAD_STATS: "Management.Groups.LEAD_STATS" /* LEAD_STATS */,
      LOCATION: "Management.Groups.LOCATION" /* LOCATION */,
      MARKETING: "Management.Groups.MARKETING" /* MARKETING */,
      MEMBER: "Management.Groups.MEMBER" /* MEMBER */,
      SETTINGS: "Management.Groups.SETTINGS" /* SETTINGS */,
      INTEGRATION: "Management.Groups.INTEGRATION" /* INTEGRATION */,
      TRACK_DRIVE: "Management.Groups.TRACK_DRIVE" /* TRACK_DRIVE */,
      PING_LOGS: "Management.Groups.PING_LOGS" /* PING_LOGS */,
      TASK: "Management.Groups.TASK" /* TASK */,
      GLOBAL_TRACKDRIVE: "Management.Groups.GLOBAL_TRACKDRIVE" /* GLOBAL_TRACKDRIVE */,
      VIEW_TRANSFERS_OVERVIEW: "Management.Groups.VIEW_TRANSFERS_OVERVIEW" /* VIEW_TRANSFERS_OVERVIEW */
    },
    Members: {
      CREATE: "Management.Members.CREATE" /* CREATE */,
      VIEW: "Management.Members.VIEW" /* VIEW */,
      DELETE: "Management.Members.DELETE" /* DELETE */,
      UPDATE: "Management.Members.UPDATE" /* UPDATE */,
      ALL: "Management.Members.ALL" /* ALL */,
      GROUPS: "Management.Members.GROUPS" /* GROUPS */,
      ASSIGN_TO_GROUP: "Management.Members.ASSIGN_GROUP" /* ASSIGN_TO_GROUP */,
      UNASSIGN_FROM_GROUP: "Management.Members.UNASSIGN_FROM_GROUP" /* UNASSIGN_FROM_GROUP */,
      SITES: "Management.Members.SITES" /* SITES */,
      ACCOUNT_MANAGE: "Management.Members.ACCOUNT_MANAGE" /* ACCOUNT_MANAGE */,
      BRAND: "Management.Members.BRAND" /* BRAND */,
      GROUP: "Management.Members.GROUP" /* GROUP */,
      LEAD: "Management.Members.LEAD" /* LEAD */,
      LEAD_STATS: "Management.Members.LEAD_STATS" /* LEAD_STATS */,
      SUPER: "Management.Members.SUPER" /* SUPER */,
      USAGE_STATUS: "Management.Members.USAGE_STATUS" /* USAGE_STATUS */,
      INTEGRATION: "Management.Members.INTEGRATION" /* INTEGRATION */,
      NOTIFICATION: "Management.Members.NOTIFICATION" /* NOTIFICATION */,
      TASK: "Management.Members.TASK" /* TASK */,
      VIEW_MODIFY_UNCLAIMED_AGENT: "Management.Members.VIEW_MODIFY_UNCLAIMED_AGENT" /* VIEW_MODIFY_UNCLAIMED_AGENT */,
      VIEW_MODIFY_CLAIMED_AGENT: "Management.Members.VIEW_MODIFY_CLAIMED_AGENT" /* VIEW_MODIFY_CLAIMED_AGENT */
    },
    Tenants: {
      VIEW: "Management.Tenants.VIEW" /* VIEW */,
      UPDATE: "Management.Tenants.UPDATE" /* UPDATE */
    },
    Onboard: {
      CREATE_NEW_BRAND: "Mangement.Onboard.CREATE_NEW_BRAND" /* CREATE_NEW_BRAND */,
      CREATE_WIZARD: "Management.Onboard.CREATE_WIZARD" /* CREATE_WIZARD */,
      BRAND: "Management.Onboard.BRAND" /* BRAND */,
      TENANT: "Management.Onboard.TENANT" /* TENANT */,
      CONE_ZONE: "Management.Onboard.CONE_ZONE" /* CONE_ZONE */,
      CONE_ZONE_SUPER: "Management.Onboard.CONE_ZONE_SUPER" /* CONE_ZONE_SUPER */
    },
    Profile: {
      VIEW_MODIFY: "Management.Profile.VIEW_MODIFY" /* VIEW_MODIFY */,
      INTEGRATION: "Management.Profile.INTEGRATION" /* INTEGRATION */,
      BLOG: "Management.Profile.BLOG" /* BLOG */,
      REVIEWS: "Management.Profile.REVIEWS" /* REVIEWS */
    }
  }
};

// src/scopes/scope-info.ts
var scopeInfo = {
  General: {
    Leads: [
      {
        scope: "General.Leads.SUPER" /* SUPER */,
        description: "Allow role to have tenant level access to leads"
      },
      {
        scope: "General.Leads.BRAND" /* BRAND */,
        description: "Allow role to have brand level access to leads"
      },
      {
        scope: "General.Leads.VIEW" /* VIEW */,
        description: "Allow role to view leads (Navbar)"
      },
      {
        scope: "General.Leads.VIEW_HIDDEN" /* VIEW_HIDDEN */,
        description: "Allow role to view hidden leads"
      },
      {
        scope: "General.Leads.REASSIGN" /* REASSIGN */,
        description: "Allow role to reassign a lead"
      },
      {
        scope: "General.Leads.CREATE" /* CREATE */,
        description: "Allow role to create leads"
      },
      {
        scope: "General.Leads.DELETE" /* DELETE */,
        description: "Allow role to delete leads"
      },
      {
        scope: "General.Leads.UPDATE" /* UPDATE */,
        description: "Allow role to update leads"
      },
      {
        scope: "General.Leads.WARM_TRANSFER" /* WARM_TRANSFER */,
        description: "Allow role to warm transfer"
      },
      {
        scope: "General.Leads.LEAD_SOURCE_FILTER" /* LEAD_SOURCE_FILTER */,
        description: "Allow role to filter by lead sources"
      },
      {
        scope: "General.Leads.NOTES" /* NOTES */,
        description: "Allow role to interact using notes"
      },
      {
        scope: "General.Leads.STAGE_CHANGE" /* STAGE_CHANGE */,
        description: "Allow role to view stage changes"
      },
      {
        scope: "General.Leads.LEAD_ASSIST" /* LEAD_ASSIST */,
        description: "Allow role to use lead assist"
      },
      {
        scope: "General.Leads.ONLY_PRIMARY_GROUP" /* ONLY_PRIMARY_GROUP */,
        description: "Allow role to see only primary group leads"
      },
      {
        scope: "General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS" /* RESTRICTED_LEAD_SOURCE_ACCESS */,
        description: "Restrict role to access leads based on restricted lead source"
      },
      {
        scope: "General.Leads.HIDE_OWNER_INFO" /* HIDE_OWNER_INFO */,
        description: "Hide owner info from dashboard/leads table/lead detail"
      }
    ],
    Settings: [
      {
        scope: "General.Settings.ACCESS",
        description: "Allow role to access tra panel"
      }
    ]
  },
  Management: {
    Account: [
      {
        scope: "Management.Members.ACCOUNT_MANAGE" /* ACCOUNT_MANAGE */,
        description: "Allow role to manage their account"
      }
    ],
    Roles: [
      {
        scope: "Management.Roles.CREATE",
        description: "Allow role to create role"
      },
      {
        scope: "Management.Roles.DELETE",
        description: "Allow role to delete role"
      },
      {
        scope: "Management.Roles.UPDATE",
        description: "Allow role to update role"
      },
      {
        scope: "Management.Roles.LIST",
        description: "Allow role to list role"
      },
      {
        scope: "Management.Roles.IS_SUPER_ADMIN",
        description: "Allow role to have super admin access"
      },
      {
        scope: "Management.Roles.IS_BRAND_ADMIN",
        description: "Allow role to have brand admin access"
      }
    ],
    Campaigns: [
      {
        scope: "Management.Campaigns.SUPER" /* SUPER */,
        description: "Allow role to have tenant level access to campaigns"
      },
      {
        scope: "Management.Campaigns.BRAND" /* BRAND */,
        description: "Allow role to have brand level access to campaigns"
      },
      {
        scope: "Management.Campaigns.VIEW" /* VIEW */,
        description: "Allow role to view campaign (Navbar)"
      },
      {
        scope: "Management.Campaigns.CREATE_ASSIGNMENT",
        description: "Allow role to create campaign assignments"
      },
      {
        scope: "Management.Campaigns.UPDATE_ASSIGNMENT",
        description: "Allow role to update campaign assignments"
      },
      {
        scope: "Management.Campaigns.ASSIGNED",
        description: "Allow role to access campaign assigned table"
      },
      {
        scope: "Management.Campaigns.DELETE_ASSIGNED",
        description: "Allow role to delete assigned campaign"
      },
      {
        scope: "Management.Campaigns.ALL",
        description: "Allow role to access campaign table"
      },
      {
        scope: "Management.Campaigns.CREATE",
        description: "Allow role to create campaign"
      },
      {
        scope: "Management.Campaigns.UPDATE",
        description: "Allow role to update campaign"
      }
    ],
    Brands: [
      {
        scope: "Management.Brands.CREATE",
        description: "Allow role to create brands"
      },
      {
        scope: "Management.Brands.DELETE",
        description: "Allow role to delete brands"
      },
      {
        scope: "Management.Brands.UPDATE",
        description: "Allow role to update brands"
      },
      {
        scope: "Management.Brands.VIEW",
        description: "Allow role to view brands"
      },
      {
        scope: "Management.Brands.ZOHO_SYNC",
        description: "Allow role to perform Zoho Sync"
      },
      {
        scope: "Management.Brands.ALL",
        description: "Allow role to access brands table"
      },
      {
        scope: "Management.Brands.LOCATION_ADD",
        description: "Allow role to add location on brands"
      },
      {
        scope: "Management.Brands.LOCATION_REMOVE",
        description: "Allow role to remove location on brands"
      },
      {
        scope: "Management.Brands.LOCATION_ASSIGNED",
        description: "Allow role to access assigned locations on brands"
      },
      {
        scope: "Management.Brands.BILLING" /* BILLING */,
        description: "Allow role to access billing table and dashboard"
      }
    ],
    Groups: [
      {
        scope: "Management.Groups.VIEW",
        description: "Allow role to view groups"
      },
      {
        scope: "Management.Groups.CREATE",
        description: "Allow role to create groups"
      },
      {
        scope: "Management.Groups.DELETE",
        description: "Allow role to delete groups"
      },
      {
        scope: "Management.Groups.UPDATE",
        description: "Allow role to update groups"
      },
      {
        scope: "Management.Groups.ALL",
        description: "Allow role to access groups table"
      },
      {
        scope: "Management.Groups.AVAILABLE",
        description: "Allow role to access available groups"
      },
      {
        scope: "Management.Groups.IN_BRAND",
        description: "Allow role to access in-brand section"
      },
      {
        scope: "Management.Groups.ASSIGN_LOCATION",
        description: "Allow role to assign location to groups"
      },
      {
        scope: "Management.Groups.UNASSIGN_LOCATION",
        description: "Allow role to unassign location to groups"
      },
      {
        scope: "Management.Groups.LOCATION_ASSIGNED",
        description: "Allow role to access assigned location to groups"
      },
      {
        scope: "Management.Groups.SEND_TEST_LEAD",
        description: "Allow role to send test lead to groups"
      },
      {
        scope: "Management.Groups.PING_ZIPS",
        description: "Allow role to ping zips"
      },
      {
        scope: "Management.Groups.PING_STATS",
        description: "Allow role to ping stats"
      },
      {
        scope: "Management.Groups.DELETE_PING_ZIP",
        description: "Allow role to delete ping zip"
      },
      {
        scope: "Management.Groups.RESET_PING_ZIP",
        description: "Allow role to reset ping zip"
      },
      {
        scope: "Management.Groups.SUPER" /* SUPER */,
        description: "Allow role to see all tenant groups"
      },
      {
        scope: "Management.Groups.BRAND" /* BRAND */,
        description: "Allow role to see all brand groups in brand"
      },
      {
        scope: "Management.Groups.MEMBER" /* MEMBER */,
        description: "Allow role to see group members (tab)"
      },
      {
        scope: "Management.Groups.LEAD" /* LEAD */,
        description: "Allow role to see group leads (tab)"
      },
      {
        scope: "Management.Groups.LEAD_STATS" /* LEAD_STATS */,
        description: "Allow role to see lead stats (tab)"
      },
      {
        scope: "Management.Groups.MARKETING" /* MARKETING */,
        description: "Allow role to see group marketing (tab)"
      },
      {
        scope: "Management.Groups.LOCATION" /* LOCATION */,
        description: "Allow role to see assigned location (tab)"
      },
      {
        scope: "Management.Groups.SETTINGS" /* SETTINGS */,
        description: "Allow role to see group settings (tab)"
      },
      {
        scope: "Management.Groups.INTEGRATION" /* INTEGRATION */,
        description: "Allow role to see group integrations (tab)"
      },
      {
        scope: "Management.Groups.TRACK_DRIVE" /* TRACK_DRIVE */,
        description: "Allow role to see group trackdrive (tab)"
      },
      {
        scope: "Management.Groups.ADD_UPDATE_TRACKDRIVE" /* ADD_UPDATE_TRACKDRIVE */,
        description: "Allow role to see add/update/delete trackdrive"
      },
      {
        scope: "Management.Groups.PING_LOGS" /* PING_LOGS */,
        description: "Allow role to Access Ping LOGS"
      },
      {
        scope: "Management.Groups.GLOBAL_TRACKDRIVE" /* GLOBAL_TRACKDRIVE */,
        description: "Allow role to see global trackdrive table"
      },
      {
        scope: "Management.Groups.VIEW_TRANSFERS_OVERVIEW" /* VIEW_TRANSFERS_OVERVIEW */,
        description: "Allow role to see transfers overview table"
      }
    ],
    Members: [
      {
        scope: "Management.Members.VIEW" /* VIEW */,
        description: "Allow role to view members (Navbar)"
      },
      {
        scope: "Management.Members.BRAND" /* BRAND */,
        description: "Allow role to access members of a brand"
      },
      {
        scope: "Management.Members.SUPER" /* SUPER */,
        description: "Allow role to access members of a Tenant. Either select brand or super"
      },
      {
        scope: "Management.Members.GROUP" /* GROUP */,
        description: "Allow role to access members group"
      },
      {
        scope: "Management.Members.CREATE" /* CREATE */,
        description: "Allow role to create new members"
      },
      {
        scope: "Management.Members.DELETE" /* DELETE */,
        description: "Allow role to delete members"
      },
      {
        scope: "Management.Members.UPDATE" /* UPDATE */,
        description: "Allow role to update members"
      },
      {
        scope: "Management.Members.ASSIGN_GROUP" /* ASSIGN_TO_GROUP */,
        description: "Allow role to assign members to groups"
      },
      {
        scope: "Management.Members.UNASSIGN_FROM_GROUP" /* UNASSIGN_FROM_GROUP */,
        description: "Allow role to unassign member from groups"
      },
      {
        scope: "Management.Members.SITES" /* SITES */,
        description: "Allow role to access sites of members"
      },
      {
        scope: "Management.Members.LEAD" /* LEAD */,
        description: "Allow role to access member leads (tab)"
      },
      {
        scope: "Management.Members.LEAD_STATS" /* LEAD_STATS */,
        description: "Allow role to access member leads stats (tab)"
      },
      {
        scope: "Management.Members.INTEGRATION" /* INTEGRATION */,
        description: "Allow role to access member integration (tab)"
      },
      {
        scope: "Management.Members.NOTIFICATION" /* NOTIFICATION */,
        description: "Allow role to access member notification (tab)"
      },
      {
        scope: "Management.Members.USAGE_STATUS" /* USAGE_STATUS */,
        description: "Allow role to export member usage status"
      },
      {
        scope: "Management.Members.VIEW_MODIFY_UNCLAIMED_AGENT" /* VIEW_MODIFY_UNCLAIMED_AGENT */,
        description: "Allow role to view and modify unclaimed agent"
      },
      {
        scope: "Management.Members.VIEW_MODIFY_CLAIMED_AGENT" /* VIEW_MODIFY_CLAIMED_AGENT */,
        description: "Allow role to view and modify a claimed agent"
      }
    ],
    Tenants: [
      {
        scope: "Management.Tenants.VIEW",
        description: "Allow role to view tenants"
      },
      {
        scope: "Management.Tenants.UPDATE",
        description: "Allow role to update tenants"
      }
    ],
    Onboard: [
      {
        scope: "Management.Onboard.TENANT" /* TENANT */,
        description: "Allow role to onboard a member for tenant"
      },
      {
        scope: "Management.Onboard.BRAND" /* BRAND */,
        description: "Allow role to onboard a member for a brand"
      },
      {
        scope: "Management.Onboard.CONE_ZONE" /* CONE_ZONE */,
        description: "Allow role to use cone zone"
      },
      {
        scope: "Management.Onboard.CONE_ZONE_SUPER" /* CONE_ZONE_SUPER */,
        description: "Allow role to use cone zone super"
      }
    ],
    Tasks: [
      {
        scope: "Management.Tasks.VIEW" /* VIEW */,
        description: "Allow Role to acces task management side nav"
      },
      {
        scope: "Management.Tasks.SUPER" /* SUPER */,
        description: "Allow role have super level control over task management"
      },
      {
        scope: "Management.Tasks.BRAND" /* BRAND */,
        description: "Allow role to have brand level control over task management"
      },
      {
        scope: "Management.Groups.TASK" /* TASK */,
        description: "Allow role to see group tasks (tab)"
      },
      {
        scope: "Management.Members.TASK" /* TASK */,
        description: "Allow role to access member tasks (tab)"
      }
    ],
    Profile: [
      {
        scope: "Management.Profile.VIEW_MODIFY" /* VIEW_MODIFY */,
        description: "Allow role to view and modify profile"
      },
      {
        scope: "Management.Profile.INTEGRATION" /* INTEGRATION */,
        description: "Allow role to access profile integrations"
      },
      {
        scope: "Management.Profile.BLOG" /* BLOG */,
        description: "Allow role to access profile blog"
      },
      {
        scope: "Management.Profile.REVIEWS" /* REVIEWS */,
        description: "Allow role to access profile reviews"
      }
    ]
  }
};

// src/scopes/index.ts
var scope = {
  config,
  info: scopeInfo
};

// src/utils/to-lat-lon.ts
function tile2long(x, z4) {
  return x / 2 ** z4 * 360 - 180;
}
function tile2lat(y, z4) {
  const n = Math.PI - 2 * Math.PI * y / 2 ** z4;
  return 180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
}
var toLonLat = (x, y, z4) => [
  tile2long(x, z4),
  tile2lat(y, z4)
];

// src/utils/parse-message-for-mention.ts
var parseMessageForMention = (message) => {
  const regex = /@\[([^\]]+)\]\((\d+)\)/g;
  let match;
  let lastIndex = 0;
  const parts = [];
  while ((match = regex.exec(message)) !== null) {
    if (match.index > lastIndex) {
      parts.push(message.substring(lastIndex, match.index));
    }
    parts.push({ name: match[1], id: match[2] });
    lastIndex = match.index + match[0].length;
  }
  if (lastIndex < message.length) {
    parts.push(message.substring(lastIndex));
  }
  return parts;
};

// src/utils/get-price-on-population.ts
var populationPricingModel = [
  { from: 157e4, to: Infinity, price: 15e3 },
  { from: 375e3, to: 1569999, price: 7500 },
  { from: 175e3, to: 374999, price: 5e3 },
  { from: 1e5, to: 174999, price: 2500 },
  { from: 5e4, to: 99999, price: 1500 },
  { from: 4e4, to: 49999, price: 1e3 },
  { from: 5e3, to: 39999, price: 500 },
  { from: 0, to: 4999, price: 100 }
];
var getPriceBasedOnPopulation = (population) => {
  if (typeof population !== "number" || isNaN(population) || population < 0) {
    return 0;
  }
  const range = populationPricingModel.find(
    (r) => population >= r.from && population <= (r.to === Infinity ? population : r.to)
  );
  if (!range) {
    throw new Error("Population out of range: " + population);
  }
  return range.price;
};

// src/utils/social-links/types.ts
var TYPE_DESKTOP = 0;
var TYPE_MOBILE = 1;
var TYPE_DEFAULT = Infinity;

// src/utils/social-links/profiles/facebook.ts
var facebook = {
  name: "facebook",
  matches: [
    {
      match: "(https?://)?(www.)?facebook.com/({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://facebook.com/{PROFILE_ID}"
    },
    {
      match: "(https?://)?m.facebook.com/({PROFILE_ID})/?",
      group: 2,
      type: TYPE_MOBILE,
      pattern: "https://m.facebook.com/{PROFILE_ID}"
    },
    {
      match: "(https?://)?(www.)?facebook.com/people/([^/]+)/([^/?]+)/?",
      group: 4,
      type: TYPE_DESKTOP,
      pattern: "https://www.facebook.com/people/{NAME}/{PROFILE_ID}"
    },
    {
      match: "(https?://)?(www.)?facebook.com/profile.php\\?id=({PROFILE_ID})",
      // New match for profile.php URLs
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://www.facebook.com/profile.php?id={PROFILE_ID}"
      // Pattern for this match
    },
    {
      match: "({PROFILE_ID})",
      group: 1
    }
  ]
};

// src/utils/social-links/profiles/github.ts
var github = {
  name: "github",
  matches: [
    {
      match: "(https?://)?(www.)?github.com/({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://github.com/{PROFILE_ID}"
    },
    { match: "({PROFILE_ID})", group: 1 }
  ]
};

// src/utils/social-links/profiles/instagram.ts
var instagram = {
  name: "instagram",
  matches: [
    {
      match: "(https?://)?(www.)?instagram.com/({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://instagram.com/{PROFILE_ID}"
    },
    {
      match: "(https?://)?m.instagram.com/({PROFILE_ID})/?",
      group: 2,
      type: TYPE_MOBILE,
      pattern: "https://m.instagram.com/{PROFILE_ID}"
    },
    { match: "@?({PROFILE_ID})", group: 1 }
  ]
};

// src/utils/social-links/profiles/linkedin.ts
var linkedin = {
  name: "linkedin",
  matches: [
    {
      match: "(https?://)?([a-z]{2,3}.)?linkedin.com/in/({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://linkedin.com/in/{PROFILE_ID}"
    },
    {
      match: "(https?://)?([a-z]{2,3}.)?linkedin.com/mwlite/in/({PROFILE_ID})/?",
      group: 3,
      type: TYPE_MOBILE,
      pattern: "https://linkedin.com/mwlite/in/{PROFILE_ID}"
    },
    {
      match: "(https?://)?([a-z]{2,3}.)?linkedin.com/pub/([a-z]+)-([a-z]+)/(\\d+)/(\\d+)/(\\d+)/?",
      group: 0,
      type: TYPE_DESKTOP,
      pattern: "https://linkedin.com/in/{FIRST_NAME}-{LAST_NAME}-{PART3}{PART2}{PART1}"
    },
    {
      match: "({PROFILE_ID})",
      group: 1
    }
  ]
};

// src/utils/social-links/profiles/pinterest.ts
var pinterest = {
  name: "pinterest",
  matches: [
    {
      match: "(https?://)?([a-z]{1,3}.)?pinterest.com/({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://pinterest.com/{PROFILE_ID}"
    },
    { match: "@?({PROFILE_ID})", group: 1 }
  ]
};

// src/utils/social-links/profiles/tiktok.ts
var tiktok = {
  name: "tiktok",
  matches: [
    {
      match: "(https?://)?(www.)?tiktok.com/@({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://tiktok.com/@{PROFILE_ID}"
    },
    { match: "@?({PROFILE_ID})", group: 1 }
  ]
};

// src/utils/social-links/profiles/twitter.ts
var twitter = {
  name: "twitter",
  matches: [
    {
      match: "(https?://)?(www.)?twitter.com/@?({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://twitter.com/{PROFILE_ID}"
    },
    {
      match: "(https?://)?mobile.twitter.com/@?({PROFILE_ID})/?",
      group: 2,
      type: TYPE_MOBILE,
      pattern: "https://mobile.twitter.com/{PROFILE_ID}"
    },
    {
      match: "(https?://)?(www.)?x.com/@?({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://x.com/{PROFILE_ID}"
    },
    {
      match: "(https?://)?mobile.x.com/@?({PROFILE_ID})/?",
      group: 2,
      type: TYPE_MOBILE,
      pattern: "https://mobile.x.com/{PROFILE_ID}"
    },
    { match: "@?({PROFILE_ID})", group: 1 }
  ]
};

// src/utils/social-links/profiles/youtube.ts
var youtube = {
  name: "youtube",
  matches: [
    {
      match: "(https?://)?(www.)?youtube.com/@?({PROFILE_ID})/?",
      group: 3,
      type: TYPE_DESKTOP,
      pattern: "https://youtube.com/@{PROFILE_ID}"
    },
    {
      match: "(https?://)?(www.)?youtube.com/channel/({PROFILE_ID})/?",
      group: 3
    },
    {
      match: "(https?://)?(www.)?youtube.com/user/({PROFILE_ID})/?",
      group: 3
    },
    {
      match: "(https?://)?m.youtube.com/c/({PROFILE_ID})/?",
      group: 2
    },
    { match: "({PROFILE_ID})", group: 1 }
  ]
};

// src/utils/social-links/profiles/index.ts
var PREDEFINED_PROFILES = [
  facebook,
  github,
  instagram,
  linkedin,
  pinterest,
  tiktok,
  twitter,
  youtube
];

// src/utils/social-links/main.ts
var PROFILE_ID = "[A-Za-z0-9_\\-\\.]+";
var QUERY_PARAM = "(\\?.*)?";
var createRegexp = (profileMatch, config2) => {
  const str = profileMatch.match.replace("{PROFILE_ID}", `${PROFILE_ID}`);
  const isTyped = typeof profileMatch.type !== "undefined";
  const regexp = new RegExp(
    [
      "^",
      str,
      ...config2.allowQueryParams && isTyped ? [QUERY_PARAM] : [],
      "$"
    ].join("")
  );
  return regexp;
};
var findIndex = (matches, link, config2) => {
  return (matches != null ? matches : []).findIndex(
    (match) => createRegexp(match, config2).test(link)
  );
};
var DEFAULT_CONFIG = {
  usePredefinedProfiles: true,
  trimInput: true,
  allowQueryParams: false
};
var SocialLinks = class {
  constructor(config2 = DEFAULT_CONFIG) {
    if (typeof config2 === "boolean") {
      config2 = { usePredefinedProfiles: config2 };
    }
    this.config = __spreadValues(__spreadValues({}, DEFAULT_CONFIG), config2);
    this.profiles = /* @__PURE__ */ new Map();
    if (this.config.usePredefinedProfiles) {
      PREDEFINED_PROFILES.map(
        ({ name, matches }) => this.addProfile(name, matches)
      );
    }
  }
  trim(input) {
    return this.config.trimInput ? input.trim() : input;
  }
  addProfile(profileName, profileMatches) {
    if (this.hasProfile(profileName))
      return false;
    this.profiles.set(profileName, profileMatches);
    return true;
  }
  cleanProfiles() {
    this.profiles.clear();
  }
  isValid(profileName, link) {
    if (!this.hasProfile(profileName))
      return false;
    const matches = this.profiles.get(profileName);
    return findIndex(matches, this.trim(link), this.config) !== -1;
  }
  getProfileId(profileName, link) {
    var _a2, _b;
    if (!this.hasProfile(profileName))
      throw new Error(`There is no profile ${profileName} defined`);
    const matches = (_a2 = this.profiles.get(profileName)) != null ? _a2 : [];
    const trimmed = this.trim(link);
    const idx = findIndex(matches, trimmed, this.config);
    if (idx === -1)
      throw new Error(`Link has not matched with profile ${profileName}`);
    return ((_b = trimmed.match(createRegexp(matches[idx], this.config))) != null ? _b : [])[matches[idx].group];
  }
  getLink(profileName, id, type = TYPE_DEFAULT) {
    var _a2, _b;
    if (!this.hasProfile(profileName))
      throw new Error(`There is no profile ${profileName} defined`);
    const matches = (_a2 = this.profiles.get(profileName)) != null ? _a2 : [];
    const weakType = type === TYPE_DEFAULT ? TYPE_DESKTOP : type;
    const idx = matches.findIndex((match) => {
      if (type === TYPE_DEFAULT)
        return true;
      return match.type === weakType;
    });
    if (idx === -1)
      throw new Error(`There is no pattern for profile ${profileName}`);
    return ((_b = matches[idx].pattern) != null ? _b : "").replace(
      "{PROFILE_ID}",
      `${this.trim(id)}`
    );
  }
  sanitize(profileName, link, type = TYPE_DEFAULT) {
    var _a2, _b;
    const trimmed = this.trim(link);
    const profileId = this.getProfileId(profileName, trimmed);
    const matches = (_a2 = this.profiles.get(profileName)) != null ? _a2 : [];
    const idx = findIndex(matches, trimmed, this.config);
    const matchedType = type !== TYPE_DEFAULT ? type : (_b = matches[idx].type) != null ? _b : TYPE_DEFAULT;
    return this.getLink(profileName, profileId, matchedType);
  }
  hasProfile(profileName) {
    return this.profiles.has(profileName);
  }
  getProfileNames() {
    return [...this.profiles.keys()];
  }
  // list all matching profiles sorted by score
  scoreProfiles(link) {
    return this.getProfileNames().map((profileName) => {
      const matches = this.profiles.get(profileName);
      const score = (matches || []).reduce((sum, match) => {
        return sum + (createRegexp(match, this.config).test(link) ? 1 : 0);
      }, 0);
      return { profileName, score };
    }).filter((obj) => obj.score > 0).sort((a, b) => b.score - a.score);
  }
  // return first matching profile
  detectProfile(link) {
    const scores = this.scoreProfiles(link);
    if (scores.length === 0)
      return "";
    return scores[0].profileName;
  }
};

// src/utils/generate-value-url.ts
var generateValueUrl = (validatedAddress) => {
  var _a2;
  const property = validatedAddress == null ? void 0 : validatedAddress.Property;
  if (!property)
    return "";
  const propertyID = property.PropertyId || property.ExternalId || "";
  const fullAddress = (_a2 = property.FullAddress) == null ? void 0 : _a2.replace(/,/g, "").replace(/ /g, "-").replace("#", "unit-").trim().toLowerCase();
  return fullAddress ? `/my-value/${fullAddress}${propertyID ? `-${propertyID}` : ""}` : "";
};

// src/lib/todos/schema.ts
var schema_exports = {};
__export(schema_exports, {
  assignMember: () => assignMember,
  createComment: () => createComment,
  createFullEpic: () => createFullEpic,
  createTodo: () => createTodo,
  createTodoEpic: () => createTodoEpic,
  createTodoEpicResponse: () => createTodoEpicResponse,
  createTodoList: () => createTodoList,
  createTodoResponse: () => createTodoResponse,
  deleteTodo: () => deleteTodo,
  entityTodosResponse: () => entityTodosResponse,
  epicParams: () => epicParams,
  getAssignedMemberById: () => getAssignedMemberById,
  getCommentById: () => getCommentById,
  getEntityTodoEpicById: () => getEntityTodoEpicById,
  getEntityTodoEpics: () => getEntityTodoEpics,
  getTodoById: () => getTodoById,
  getTodoListById: () => getTodoListById,
  taskTableQuery: () => taskTableQuery,
  taskTableQueryOpts: () => taskTableQueryOpts,
  todo: () => todo,
  todoItemParams: () => todoItemParams,
  toggle: () => toggle,
  updateTodo: () => updateTodo,
  updateTodoEpic: () => updateTodoEpic,
  updateTodoList: () => updateTodoList
});
import z2 from "zod";

// src/lib/base/schema.ts
import z from "zod";
var tablePagination = z.object({
  page: z.coerce.number().min(0),
  limit: z.coerce.number().max(100)
});
var tableQuery = z.object({
  searchText: z.string().optional(),
  pagination: tablePagination
});
var tableData = z.object({
  currentPage: z.number(),
  limit: z.number(),
  totalItems: z.number()
});
var tableFilterExpression = z.object({
  operator: z.union([
    z.literal("contains"),
    z.literal("startsWith"),
    z.literal("endsWith"),
    z.literal("=")
  ])
});

// src/lib/todos/schema.ts
var base = z2.object({
  entityId: z2.number(),
  title: z2.string().min(3).max(255),
  description: z2.string().min(10).max(3e3),
  priority: z2.union([z2.literal("high"), z2.literal("medium"), z2.literal("low")]),
  deadlineAt: z2.date().optional().nullable(),
  completedAt: z2.date().optional().nullable()
});
var entityType = z2.union([
  z2.literal("cone_zone"),
  z2.literal("member"),
  z2.literal("brand_group"),
  z2.literal("customer"),
  z2.literal("brand")
]);
var memberSchema = z2.object({
  id: z2.number(),
  firstName: z2.string(),
  email: z2.string(),
  lastName: z2.string()
});
var todo = base.merge(
  z2.object({
    id: z2.number(),
    type: entityType,
    createdAt: z2.date().optional(),
    updatedAt: z2.date().optional(),
    createdById: z2.number().optional().nullable(),
    archivedAt: z2.date().optional().nullable(),
    completedById: z2.number().optional().nullable(),
    createdBy: memberSchema.optional().nullable(),
    completedBy: memberSchema.optional().nullable()
  })
);
var entityTodosResponse = todo.array();
var createTodo = z2.object({
  title: z2.string().min(1).max(255),
  description: z2.string().max(3e3).default(""),
  todoListId: z2.number()
});
var ent = z2.object({
  type: entityType,
  entityId: z2.coerce.number()
});
var todoItemParams = z2.object({
  epicId: z2.coerce.number(),
  listId: z2.coerce.number()
}).merge(ent);
var createTodoResponse = z2.object({
  id: z2.number(),
  createdAt: z2.string(),
  updatedAt: z2.string()
});
var updateTodo = z2.object({
  id: z2.number()
}).merge(createTodo.partial());
var deleteTodo = base.merge(
  z2.object({
    id: z2.number(),
    entityId: z2.number()
  })
);
var getEntityTodoEpics = z2.object({
  type: entityType,
  entityId: z2.coerce.number()
});
var toggleType = z2.union([
  z2.literal("archive"),
  z2.literal("complete"),
  z2.literal("deadline")
]);
var toggle = z2.object({
  enabled: z2.enum(["true", "false"]).transform((value) => value === "true"),
  type: toggleType
});
var getTodoById = z2.object({
  id: z2.coerce.number()
}).merge(todoItemParams);
var getEntityTodoEpicById = getEntityTodoEpics.merge(
  z2.object({
    id: z2.coerce.number()
  })
);
var createTodoEpic = z2.object({
  title: z2.string().min(1).max(255),
  description: z2.string().max(3e3).optional().default(""),
  createdById: z2.number().optional(),
  due: z2.string().optional().nullable(),
  entityId: z2.number(),
  type: entityType,
  status: z2.union([
    z2.literal("archived"),
    z2.literal("completed"),
    z2.literal("todo"),
    z2.literal("in-progress")
  ])
});
var updateTodoEpic = z2.object({ id: z2.number() }).merge(createTodoEpic.partial());
var createTodoEpicResponse = z2.object({ id: z2.number() }).merge(createTodoEpic);
var createTodoList = z2.object({
  title: z2.string(),
  todoEpicId: z2.number()
});
var epicParams = z2.object({
  epicId: z2.coerce.number()
}).merge(ent);
var getTodoListById = z2.object({
  id: z2.coerce.number()
}).merge(epicParams);
var updateTodoList = z2.object({
  id: z2.number()
}).merge(createTodoList.partial());
var createComment = z2.object({
  comment: z2.string(),
  todoEpicId: z2.number(),
  memberId: z2.number().optional()
});
var getCommentById = z2.object({
  id: z2.coerce.number()
}).merge(epicParams);
var getAssignedMemberById = z2.object({
  id: z2.number()
});
var assignMember = z2.object({
  memberId: z2.number(),
  todoEpicId: z2.number()
});
var _a;
var createFullEpic = z2.object({
  name: z2.string(),
  description: z2.string(),
  assigned: z2.number().array(),
  entityType,
  entityId: z2.number(),
  due: (_a = z2.string()) == null ? void 0 : _a.optional().nullable(),
  status: z2.union([
    z2.literal("archived"),
    z2.literal("completed"),
    z2.literal("todo"),
    z2.literal("in-progress")
  ]),
  taskList: z2.object({
    name: z2.string(),
    tasks: z2.object({
      name: z2.string(),
      description: z2.string()
    }).array()
  }).array()
});
var taskTableQuery = z2.object({
  entity: z2.object({
    id: z2.coerce.number(),
    entityType
  }).optional(),
  showAssigned: z2.enum(["true", "false"]).transform((value) => value === "true"),
  startDate: z2.string().optional(),
  endDate: z2.string().optional(),
  memberId: z2.coerce.number().optional()
}).merge(tableQuery);
var taskTableQueryOpts = z2.object({
  memberId: z2.coerce.number().optional(),
  brandingId: z2.coerce.number().optional(),
  showAssigned: z2.enum(["true", "false"]).transform((value) => value === "true"),
  access: z2.union([
    z2.literal("member"),
    z2.literal("brand_admin"),
    z2.literal("super_admin")
  ]).optional()
}).merge(taskTableQuery);

// src/lib/todos/types.ts
var types_exports = {};

// src/lib/todos/index.ts
var commonFunction = () => {
};
var todos = {
  schema: schema_exports,
  commonFunction
};

// src/lib/forecasting/schema.ts
var schema_exports2 = {};
__export(schema_exports2, {
  forecastingTableQuery: () => forecastingTableQuery
});
import z3 from "zod";
var forecastingTableQuery = z3.object({
  cbsa: z3.object({
    cbsa: z3.string()
  }),
  county: z3.object({
    county: z3.string()
  }).optional(),
  population: z3.object({
    value: z3.number(),
    symbol: z3.string()
  }).optional(),
  sort: z3.object({
    field: z3.union([
      z3.literal("population"),
      z3.literal("portalStatus"),
      z3.literal("suggestedleads"),
      z3.literal("advertisingConfidenceScore")
    ]),
    sort: z3.union([z3.literal("asc"), z3.literal("desc")])
  }),
  condition: z3.union([z3.literal("and"), z3.literal("or")]),
  filters: z3.array(
    z3.object({
      columnField: z3.union([z3.literal("and"), z3.literal("or")]),
      operatorValue: tableFilterExpression,
      value: z3.string()
    })
  )
}).merge(tableQuery);

// src/lib/forecasting/types.ts
var types_exports2 = {};

// src/lib/forecasting/index.ts
var forecasting = {
  schema: schema_exports2
};

// src/constants/activity-map.ts
var activities = {
  "showing-request": "Showing Request",
  "store-saved-property": "Property Save",
  "delete-saved-property": "Property Delete",
  "viewed-property": "Property View",
  "store-user-inquiry": "Inquiry",
  "store-saved-search": "Saved Search",
  "delete-saved-search": "Saved Search Delete",
  "store-lead-note": "Note Added",
  "stage-change": "Stage Change",
  registration: "Registration",
  "store-lead-note-comment": "Comment Added",
  "delete-lead-note": "Note Deleted",
  "delete-lead-note-comment": "Comment Deleted",
  "update-user-details": "User Details Updated",
  "agent-direct": "Agent Direct",
  "social-share": "Social Share",
  "email-share": "Email Share",
  "reassign-lead": "Lead Reassigned",
  "mortgage-calc": "Mortgage Calculator",
  "homeward-prelim": "Homeward Preliminary Report Generated",
  "tcpa-set": "TCPA Set"
};

// src/constants/sales/stage-map.ts
var stageMap = {
  started: {
    label: "Started",
    value: "started",
    color: "info.main",
    category: "neutral"
  },
  pending_sign: {
    label: "Pending Sign",
    value: "pending_sign",
    color: "info.main",
    category: "neutral"
  },
  signed: {
    label: "Signed",
    value: "signed",
    color: "error.main",
    category: "neutral"
  },
  paid: {
    label: "Paid",
    value: "paid",
    color: "error.main",
    category: "neutral"
  },
  paid_signed: {
    label: "Paid and Signed",
    value: "paid_signed",
    color: "error.main",
    category: "neutral"
  },
  pending_questionnaire: {
    label: "Pending Questionnaire",
    value: "pending_questionnaire",
    color: "error.main",
    category: "neutral"
  },
  idx_review: {
    label: "IDX / Review",
    value: "idx_review",
    color: "error.main",
    category: "neutral"
  },
  onboarding: {
    label: "Onboarding",
    value: "onboarding",
    color: "warning.main",
    category: "neutral"
  },
  completed: {
    label: "Complete",
    value: "completed",
    color: "success.main",
    category: "won"
  },
  collections: {
    label: "Collections",
    value: "collections",
    color: "warning.main",
    category: "neutral"
  },
  cancelled: {
    label: "Cancelled",
    value: "cancelled",
    color: "warning.main",
    category: "lost"
  },
  dead_deal: {
    label: "Dead Deal",
    value: "dead_deal",
    color: "warning.main",
    category: "lost"
  },
  pending_cancellation: {
    label: "Pending Cancellation",
    value: "pending_cancellation",
    color: "warning.main",
    category: "lost"
  },
  crm_support: {
    label: "CRM Support",
    value: "crm_support",
    color: "warning.main",
    category: "lost"
  },
  idx_hold: {
    label: "IDX Hold",
    value: "idx_hold",
    color: "warning.main",
    category: "lost"
  }
};
var stageOptions = Object.values(stageMap).map((stage) => ({
  color: stage.color,
  label: stage.label,
  value: stage.value
}));

// src/constants/notifications-map.ts
var inquiryFields = [
  "AVM_CLAIM",
  "BUYER_LEAD",
  "BUYING_TIPS",
  "AGENT_DIRECT",
  "SELLING_TIPS",
  "AVM_SELLER_LEAD",
  "DEFAULT_INQUIRY",
  "CASH_SELLER_LEAD",
  "EARLY_SELLER_LEAD",
  "REALTY_EARLY_SELLER",
  "CS_BUYER_SELLER_LEAD",
  "LEAD_MORTGAGE_CALC",
  "LOAN_REQUEST"
];
var propertyFields = [
  "LEAD_SAVED_SEARCH",
  "PROPERTY_INQUIRY",
  "LEAD_SAVED_PROPERTY"
];
var leadFields = [
  "LEAD_STATUS_UPDATE",
  "LEAD_TOUR_SCHEDULE",
  "LEAD_CALLBACK",
  "LEAD_CREATED",
  "LEAD_UPDATED",
  "LEAD_ATTEMPTED_TRANSFER",
  "LEAD_WARM_TRANSFER",
  "LEAD_FRENZY_CLAIM",
  "LEAD_HOMEWARD_PRELIMINARY_REPORT",
  "CAMPAIGN_CLICK"
];
var notesFields = [
  "NOTES_CREATED",
  "NOTES_DELETED",
  "NOTES_COMMENTED",
  "NOTES_COMMENT_DELETED"
];
var fieldDescription = {
  LEAD_MORTGAGE_CALC: {
    title: "Lead Mortgage Calculator",
    description: "Receive alerts when a lead interacts with the mortgage calculator."
  },
  AVM_CLAIM: {
    title: "AVM Claim",
    description: "Receive alerts when a homeowner claims a property and registers an account."
  },
  BUYER_LEAD: {
    title: "Buyer Lead",
    description: "Receive alerts when a new buyer lead registers."
  },
  BUYING_TIPS: {
    title: "Buying Tips",
    description: "Receive alerts when a buyer lead registers on buying tips page."
  },
  AGENT_DIRECT: {
    title: "Agent Direct",
    description: "Receive alerts when a lead submits the email agent / contact agent form."
  },
  LEAD_CREATED: {
    title: "Lead Created",
    description: "Receive alerts when a new lead is created."
  },
  LEAD_UPDATED: {
    title: "Lead Updated",
    description: "Receive notifications when a lead contact information is updated."
  },
  SELLING_TIPS: {
    title: "Selling Tips",
    description: "Receive alerts when a buyer lead registers on selling tips page."
  },
  LEAD_CALLBACK: {
    title: "Lead Callback",
    description: "Receive a notification when a lead requests a call back."
  },
  NOTES_CREATED: {
    title: "Notes Created",
    description: "Receive notifications when a note is added to a lead."
  },
  NOTES_DELETED: {
    title: "Notes Deleted",
    description: "Receive notifications when a note is deleted."
  },
  AVM_SELLER_LEAD: {
    title: "AVM Seller Lead",
    description: 'Receive alert when a seller fills out the "I want to sell" home valuation form.'
  },
  DEFAULT_INQUIRY: {
    title: "Default Inquiry",
    description: "Important notification about something"
  },
  NOTES_COMMENTED: {
    title: "Notes Commented",
    description: "Receive notifications when a comment is added."
  },
  CASH_SELLER_LEAD: {
    title: "Cash Seller Lead",
    description: "Receive a notification when a new seller lead requests a cash offer with a more immediate timeframe to sell."
  },
  PROPERTY_INQUIRY: {
    title: "Property Inquiry",
    description: "Receive alerts when a lead has submitted a property inquiry on a property."
  },
  EARLY_SELLER_LEAD: {
    title: "Early Seller Lead",
    description: "Receive a notification when a new seller lead requests a cash offer with a specified timeframe to sell."
  },
  LOAN_REQUEST: {
    title: "Loan Request",
    description: "Receive a notification on loan request inquiry."
  },
  LEAD_SAVED_SEARCH: {
    title: "Lead Saved Search",
    description: "Receive alerts when a lead has saved a property search."
  },
  LEAD_STATUS_UPDATE: {
    title: "Lead Status Update",
    description: "Receive a notification when a lead\u2019s status is updated."
  },
  LEAD_TOUR_SCHEDULE: {
    title: "Lead Tour Schedule",
    description: "Receive a notification when a lead requests to view a property."
  },
  NOTIFICATION_SOUND: {
    title: "Notification Sound",
    description: "Important notification about something"
  },
  LEAD_SAVED_PROPERTY: {
    title: "Lead Saved Property",
    description: "Receive alerts when a lead has saved a new property."
  },
  REALTY_EARLY_SELLER: {
    title: "Realty Early Seller",
    description: "Important notification about something"
  },
  CS_BUYER_SELLER_LEAD: {
    title: "CS Buyer Seller Lead",
    description: "Important notification about something"
  },
  NOTES_COMMENT_DELETED: {
    title: "Notes Comment Deleted",
    description: "Receive notifications when a comment is deleted."
  },
  LEAD_ATTEMPTED_TRANSFER: {
    title: "Lead Attempted Transfer",
    description: "Receive notifications when a lead is attempted to transfer."
  },
  LEAD_WARM_TRANSFER: {
    title: "Lead Warm Transfer",
    description: "Receive notifications when a lead is warm transferred."
  },
  LEAD_FRENZY_CLAIM: {
    title: "Lead Frenzy Claim",
    description: "Receive notifications when there is a lead to claim near me from the Frenzy pool."
  },
  LEAD_HOMEWARD_PRELIMINARY_REPORT: {
    title: "Lead Homeward Preliminary Report",
    description: "Receive notifications when a Homeward Preliminary Report is generated."
  },
  CAMPAIGN_CLICK: {
    title: "Campaign Click",
    description: "Receive notifications when a Home value or Market area report is clicked."
  }
};
var initialNotificationValues = {
  lct: {
    types: Object.keys(fieldDescription).reduce(
      (acc, key) => __spreadProps(__spreadValues({}, acc), { [key]: false }),
      {}
    ),
    enabled: false
  },
  email: {
    disableOwnerMeta: false,
    types: Object.keys(fieldDescription).reduce(
      (acc, key) => __spreadProps(__spreadValues({}, acc), { [key]: false }),
      {}
    ),
    enabled: false
  },
  phone: {
    types: Object.keys(fieldDescription).reduce(
      (acc, key) => __spreadProps(__spreadValues({}, acc), { [key]: false }),
      {}
    ),
    enabled: false
  }
};
export {
  DEFAULT_CONFIG,
  types_exports2 as ForecastingTypes,
  PREDEFINED_PROFILES,
  SocialLinks,
  TYPE_DEFAULT,
  TYPE_DESKTOP,
  TYPE_MOBILE,
  types_exports as TodoTypes,
  activities,
  fieldDescription,
  forecasting,
  generateValueUrl,
  getPriceBasedOnPopulation,
  initialNotificationValues,
  inquiryFields,
  leadFields,
  notesFields,
  parseMessageForMention,
  propertyFields,
  scope,
  stageMap,
  stageOptions,
  toLonLat,
  todos
};
