import { colors } from '@mui/material'

const colorsArray: any = [
  colors.purple[500],
  colors.red[500],
  colors.orange[500],
  colors.green[500],
  colors.lightBlue[500],
  colors.pink[500],
  colors.cyan[500],
  colors.deepOrange[500],
  colors.brown[500],
  colors.blueGrey[500],
  colors.yellow[500],
  colors.deepPurple[500],
  colors.amber[500],
]

export const getRandomColor = () => {
  return colorsArray[(colorsArray.length * Math.random()) | 0]
}
