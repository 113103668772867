export function getInitials(name: string): string {
  if (!name) return '' // This will handle null, undefined, and empty string

  const initials = name.trim().split(/\s+/gu) || []
  let output = initials.shift()?.charAt(0) || '' // Use charAt instead of spread for strings

  if (initials.length > 0) {
    output += initials.pop()?.charAt(0) || '' // Use charAt here as well
  }
  return output.toUpperCase()
}
