export const isValidURL = (str: any): boolean => {
  if (typeof str !== 'string') {
    return false
  }

  const trimmedStr = str.trim()

  if (!trimmedStr) {
    return false
  }

  try {
    const parsedUrl = new URL(str)
    const isValidScheme = /^(http|https)$/.test(parsedUrl.protocol.slice(0, -1))
    if (!isValidScheme) {
      throw new Error('Scheme is not valid.')
    }
    return true
  } catch (_) {
    return false
  }
}
