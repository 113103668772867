/* eslint-disable no-restricted-properties */
export const abbreviateNumber = (
  number: number,
  decPlaces: number = 2,
): string => {
  // Convert decimal places into a multiplier (e.g., 2 decimal places => 100)
  const factor: number = Math.pow(10, decPlaces)

  // Define number abbreviations
  const abbreviations: string[] = ['k', 'm', 'b', 't']

  // Start from the largest unit to find the appropriate abbreviation
  for (let i: number = abbreviations.length - 1; i >= 0; i--) {
    // Convert index to base unit (e.g., 1 => 1000, 2 => 1,000,000, etc.)
    const size: number = Math.pow(10, (i + 1) * 3)

    // If the number is large enough for this abbreviation
    if (size <= number) {
      // Calculate the abbreviated number
      let abbrNumber: number = number / size

      // Round to the specified number of decimal places
      abbrNumber = Math.round(abbrNumber * factor) / factor

      // Check if rounding leads to a number of 1000, then use the next higher unit
      if (abbrNumber === 1000 && i < abbreviations.length - 1) {
        abbrNumber = 1
        i++
      }

      // Return the number with its abbreviation
      return abbrNumber + abbreviations[i]
    }
  }

  // If no abbreviation is suitable, return the original number formatted
  return (Math.round(number * factor) / factor).toString()
}
