type ReplaceAndTransformConfig = {
  text: string
  start?: string
  end?: string
  exact?: string
  transform: (extractedText: string) => string
  inclusive?: boolean
}

export const replaceAndTransformText = ({
  text,
  start,
  end,
  exact,
  transform,
  inclusive = false,
}: ReplaceAndTransformConfig): string => {
  if (!text) {
    return ''
  }
  if (end !== undefined && typeof end !== 'string') {
    console.error('end must be a string')
    return text
  }
  let regex: RegExp
  let match: RegExpExecArray | null

  if (exact) {
    // Construct a regex for an exact match, considering flexible spaces and optional punctuation
    const exactEscaped = exact.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
    const exactPattern = exactEscaped
      .replace(/\s+/g, '\\s*')
      .replace(/[,.]/g, '[,.]?')
    regex = new RegExp(exactPattern, 'gi')
  } else if (start && end) {
    // Construct a regex to match any text between start and end markers
    const escapedStart = start.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
    const escapedEnd = end.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
    regex = inclusive
      ? new RegExp(`${escapedStart}([\\s\\S]*?)${escapedEnd}`, 'gi')
      : new RegExp(`(?<=${escapedStart})([\\s\\S]*?)(?=${escapedEnd})`, 'gi')
  } else {
    // If neither exact nor start/end are provided, return the text unmodified
    return text
  }

  return text.replace(regex, (match, ...groups) => {
    // Determine which group to use based on whether the match should be inclusive
    const extractedText = inclusive || exact ? match : groups[0]
    return transform(extractedText)
  })
}

// RegExp.escape polyfill for environments that do not support it
if (!RegExp.escape) {
  RegExp.escape = (s: string) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}
