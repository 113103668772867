export const stringToTitle = (s: string): string => {
  if (!s?.length) return ''

  return s
    .replace(/[_-]+/g, ' ') // Replace underscores and hyphens with spaces
    .replace(/([a-z])([A-Z])|\b([A-Z]+)\b/g, (match, p1, p2, p3) => {
      if (p1 && p2) {
        // Split camelCase
        return p1 + ' ' + p2
      }
      // Convert all caps words to title case
      return p3.charAt(0) + p3.slice(1).toLowerCase()
    })
    .replace(/^\w/, c => c.toUpperCase()) // Capitalize the first character
    .trim()
}
