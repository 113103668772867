import axios from 'axios'
import { useKBar, useRegisterActions } from 'kbar'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import api from '../../../utils/api'
import { getAllLeads } from 'services/lead'

// need to get data

const searchId = 'search3'

const entityTypes = {
  M: 'ma',
  C: 'co',
  I: 'in',
}

export function useLeadSearchAction() {
  const { queryValue } = useKBar(state => ({
    queryValue: state.searchQuery,
  }))
  const [leads, setLeads] = useState([])
  const router = useRouter()

  useEffect(() => {
    let CancelToken = axios.CancelToken
    let source = CancelToken.source()
    const handleSearch = async () => {
      try {
        const response = await getAllLeads({
          queryKey: [
            'getAllLeads',
            {
              searchText: queryValue.trim(),
              leadActivity: false,
              showActive: false,
              pagination: {
                page: 0,
                limit: 1,
              },
              showAll: true,
              access: 'member',
              showHidden: false,
              tab: 'leads',
            },
          ],
        })

        setLeads(response.data ?? [])
      } catch (err) {
        console.error(err)
      } finally {
      }
    }

    if (queryValue) {
      handleSearch()
    }
    return () => {
      source.cancel('Cancelled due to stale request')
    }
  }, [queryValue])

  const createAction = lead => {
    return {
      id: lead.id,
      parent: searchId,
      name: `${lead.firstName} ${lead.lastName}`,
      subtitle: `${lead.email}`,
      keywords: `${lead.phoneNumber}`,
      section: 'Leads',
      perform: () => {
        router.push(`/leads/${lead.userId}?tab=details`)
      },
    }
  }

  const searchActions = useMemo(() => {
    const actions = []
    actions.push({
      id: 'm1',
      parent: searchId,
      name: 'M1',
      keywords: 'maa AB-1 Horses',
      section: entityTypes.M,
      perform: () => {},
    })
    actions.push({
      id: 'm2',
      parent: searchId,
      name: 'M2',
      keywords: 'maa CD-2 Houses',
      section: entityTypes.M,
      perform: () => {},
    })
    actions.push({
      id: 'c1',
      parent: searchId,
      name: 'C1',
      keywords: 'coo XY-1 Joe',
      section: entityTypes.C,
      perform: () => {},
    })
    actions.push({
      id: 'c2',
      parent: searchId,
      name: 'C2',
      keywords: 'coo QR-2 Ava',
      section: entityTypes.C,
      perform: () => {},
    })
    return actions
  }, [])

  const rootSearchAction = useMemo(
    () =>
      searchActions.length
        ? {
            id: searchId,
            name: 'Search Lead',
            shortcut: ['l'],
            keywords: 'find',
            section: 'Search',
          }
        : null,
    [searchActions],
  )

  useRegisterActions(
    [rootSearchAction, ...leads.map(lead => createAction(lead))],
    [leads],
  )
}
