export const generateValueUrl = (validatedAddress: any): string => {
  const property = validatedAddress?.Property
  if (!property) return ''

  const propertyID = property.PropertyId || property.ExternalId || ''
  const fullAddress = property.FullAddress?.replace(/,/g, '')
    .replace(/ /g, '-')
    .replace('#', 'unit-')
    .trim()
    .toLowerCase()

  return fullAddress
    ? `/my-value/${fullAddress}${propertyID ? `-${propertyID}` : ''}`
    : ''
}
