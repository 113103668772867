export const formatPhone = (input: string): string => {
  // Remove all non-digit characters
  let numberOnly = input.replace(/\D/g, '')

  // Remove leading '1' if present
  if (numberOnly.charAt(0) === '1') {
    numberOnly = numberOnly.slice(1)
  }

  // If the remaining string is empty, return the original input
  if (numberOnly.length === 0) {
    return input
  }

  // Format the number
  if (numberOnly.length <= 3) {
    return numberOnly
  } else if (numberOnly.length <= 6) {
    return `${numberOnly.slice(0, 3)}-${numberOnly.slice(3)}`
  } else {
    return `${numberOnly.slice(0, 3)}-${numberOnly.slice(
      3,
      6,
    )}-${numberOnly.slice(6, 10)}`
  }
}
