export interface Bbox {
    swLat: number;
    swLong: number;
    neLat: number;
    neLong: number;
}
export const bboxToStaticMap = (bbox: Bbox) => {
    const width = 256;
    const pixelWidth = 300;
    let angle = Number(bbox.neLong) - Number(bbox.swLong);

    if (angle < 0) {
      angle += 360;
    }

    const zoom = Math.round(
      Math.log((pixelWidth * 360) / angle / width) / Math.LN2
    );

    const lat = (Number(bbox.swLat) + Number(bbox.neLat)) / 2;
    const lng = (Number(bbox.swLong) + Number(bbox.neLong)) / 2;

    return `http://imagecdn.realty.com/static_map.png?zoom=${zoom}&lat=${lat}&lon=${lng}`;
  };
