import {
  ACCOUNT,
  DASHBOARD,
  LEADS,
  INQUIRY,
  NOTES,
  SHOWINGREQUEST,
  PROPERTY,
  SAVEDSEARCH,
} from './types/scopes/general'
import {
  BRANDS,
  GROUPS,
  MEMBER,
  ONBOARD,
  CAMPAIGNS,
  TENANT,
  ROLES,
} from './types/scopes/management'
import { Scopes } from './types/scopes'
import { SETTINGS } from './types/scopes/global'

export type ScopeName =
  | `${ACCOUNT}`
  | `${DASHBOARD}`
  | `${LEADS}`
  | `${SETTINGS}`
  | `${BRANDS}`
  | `${GROUPS}`
  | `${MEMBER}`
  | `${ONBOARD}`
  | `${ROLES}`

export const scopes: Scopes = {
  General: {
    Account: {
      MANAGE: ACCOUNT.MANAGE,
      CAN_DELETE_OWN_ACCOUNT: ACCOUNT.CAN_DELETE_OWN_ACCOUNT,
      VIEW_LOGIN_HISTORY: ACCOUNT.VIEW_LOGIN_HISTORY,
    },
    Dashboard: {
      VIEW: DASHBOARD.VIEW,
    },
    Inquiry: {
      ALL: INQUIRY.ALL,
      LEAD: INQUIRY.LEAD,
    },
    ShowingRequest: {
      ALL: SHOWINGREQUEST.ALL,
      LEAD: SHOWINGREQUEST.LEAD,
    },
    Property: {
      ALL: PROPERTY.ALL,
      LEAD: PROPERTY.LEAD,
    },
    SavedSearch: {
      ALL: SAVEDSEARCH.ALL,
      LEAD: SAVEDSEARCH.LEAD,
    },
    Leads: {
      VIEW_PROPERTIES: LEADS.VIEW_PROPERTIES,
      VIEW_REQUESTS: LEADS.VIEW_REQUESTS,
      VIEW_SEARCHES: LEADS.VIEW_SEARCHES,
      ALL: LEADS.ALL,
      REASSIGN: LEADS.REASSIGN,
      DELETE: LEADS.DELETE,
      UPDATE: LEADS.UPDATE,
      OMNI_SEARCH: LEADS.OMNISEARCH,
      VIEW: LEADS.VIEW,
      WARM_TRANSFER: LEADS.WARM_TRANSFER,
      FILTER_BY_LEAD_SOURCES: LEADS.FILTER_BY_LEAD_SOURCES,
      FILTER_BY_BRANDS: LEADS.FILTER_BY_BRANDS,
      FILTER_BY_BRAND_GROUPS: LEADS.FILTER_BY_BRAND_GROUPS,
    },
    Notes: {
      CREATE: NOTES.CREATE,
      DELETE: NOTES.DELETE,
      COMMENT: NOTES.COMMENT,
      COMMENT_DELETE: NOTES.COMMENT_DELETE,
    },
  },
  Global: {
    Settings: {
      ACCESS: SETTINGS.ACCESS,
    },
  },

  Management: {
    Roles: {
      CREATE: ROLES.CREATE,
      UPDATE: ROLES.UPDATE,
      DELETE: ROLES.DELETE,
      LIST: ROLES.LIST,
      IS_BRAND_ADMIN: ROLES.IS_BRAND_ADMIN,
      IS_SUPER_ADMIN: ROLES.IS_SUPER_ADMIN,
    },
    Campaigns: {
      CREATE_ASSIGNMENT: CAMPAIGNS.CREATE_ASSIGNMENT,
      UPDATE_ASSIGNMENT: CAMPAIGNS.UPDATE_ASSIGNMENT,
      ASSIGNED: CAMPAIGNS.ASSIGNED,
      DELETE_ASSIGNED: CAMPAIGNS.DELETE_ASSIGNED,
      ALL: CAMPAIGNS.ALL,
    },
    Brands: {
      CREATE: BRANDS.CREATE,
      DELETE: BRANDS.DELETE,
      UPDATE: BRANDS.UPDATE,
      VIEW: BRANDS.VIEW,
      ZOHO_SYNC: BRANDS.ZOHO_SYNC,
      ALL: BRANDS.ALL,
      LOCATION_ADD: BRANDS.LOCATION_ADD,
      LOCATION_REMOVE: BRANDS.LOCATION_REMOVE,
      LOCATION_ASSIGNED: BRANDS.LOCATION_ASSIGNED,
    },
    Groups: {
      VIEW: GROUPS.VIEW,
      CREATE: GROUPS.CREATE,
      DELETE: GROUPS.DELETE,
      UPDATE: GROUPS.UPDATE,
      ALL: GROUPS.ALL,
      AVAILABLE: GROUPS.AVAILABLE,
      IN_BRAND: GROUPS.IN_BRAND,
      ASSIGN_LOCATION: GROUPS.ASSIGN_LOCATION,
      UNASSIGN_LOCATION: GROUPS.UNASSIGN_LOCATION,
      LOCATION_ASSIGNED: GROUPS.LOCATION_ASSIGNED,
      SEND_TEST_LEAD: GROUPS.SEND_TEST_LEAD,
      PING_ZIP: GROUPS.PING_ZIPS,
      PING_STATS: GROUPS.PING_STATS,
      DELETE_PING_ZIP: GROUPS.DELETE_PING_ZIP,
      RESET_PING_ZIP: GROUPS.RESET_PING_ZIP,
    },

    Members: {
      CREATE: MEMBER.CREATE,
      VIEW: MEMBER.VIEW,
      DELETE: MEMBER.DELETE,
      UPDATE: MEMBER.UPDATE,
      ALL: MEMBER.ALL,
      GROUPS: MEMBER.GROUPS,
      ASSIGN_TO_GROUP: MEMBER.ASSIGN_TO_GROUP,
      UNASSIGN_FROM_GROUP: MEMBER.UNASSIGN_FROM_GROUP,
      SITES: MEMBER.SITES,
    },

    Tenants: {
      VIEW: TENANT.VIEW,
      UPDATE: TENANT.UPDATE,
    },

    Onboard: {
      CREATE_NEW_BRAND: ONBOARD.CREATE_NEW_BRAND,
      CREATE_WIZARD: ONBOARD.CREATE_WIZARD,
    },
  },
}
