import create from 'zustand'

export type AccessLevelValue = 'member' | 'super_admin' | 'brand_admin'
export type AccessLevel = {
  label: string
  value: AccessLevelValue
}

interface GlobalState {
  accessLevel: AccessLevel
  availableAccessLevel: AccessLevel[]
  setAccessLevel: (accessLevel: AccessLevel) => void
  setAvaialableAccessLevel: (levels: AccessLevel[]) => void
}

let initialAccessLevel: AccessLevel = {
  label: 'Personal',
  value: 'member',
}

if (typeof window !== 'undefined' && localStorage.getItem('accessLevel')) {
  initialAccessLevel = JSON.parse(localStorage.getItem('accessLevel'))
}

const initialAvailableAccessLevel: AccessLevel[] = [initialAccessLevel]

export const useGlobal = create<GlobalState>(set => ({
  accessLevel: initialAccessLevel,
  setAccessLevel: (accessLevel: AccessLevel) =>
    set(state => ({ ...state, accessLevel })),

  availableAccessLevel: initialAvailableAccessLevel,
  setAvaialableAccessLevel: (levels: AccessLevel[]) =>
    set(state => ({
      ...state,
      availableAccessLevel: levels,
    })),
}))
