export const slugify = (
  str: string,
  keepCasing: boolean = false,
  separator: string = '-',
) => {
  if (!str) return str // Return the original string if it is falsy

  return (keepCasing ? str : str.toLowerCase())
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, separator)
    .replace(/^-+|-+$/g, '')
}
