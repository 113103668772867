import qs from 'qs'

import {
  CreateLeadPayload,
  LeadDetailResponse,
  PaginatedLeadTable,
} from '../types/leads'
import api from '../utils/api'

export const getLeadMemberActivity = async (leadId: number) => {
  const result = await api.get(`/leads/member_activity/${leadId}`)

  return result.data
}

export const getAllLeads = async ({
  queryKey,
}: any): Promise<PaginatedLeadTable> => {
  const result = await api.get(
    `/leads?fields=id,firstName,lastName,email,lastLogin,createdAt,role,phoneNumber&${qs.stringify(
      queryKey[1],
    )}`,
  )

  return result.data
}

export const exportLeads = async query => {
  const result = await api.get(
    `/leads/export?fields=id,firstName,lastName,email,lastLogin,createdAt,role,phoneNumber&${qs.stringify(
      query,
    )}`,
  )

  return result.data
}

export const getLeadSource = async (startDate, endDate) => {
  const result = await api.get(
    `/filters/leadsource?startDate=${startDate}&endDate=${endDate}`,
  )

  return result.data
}

export const getLeadDetail = async ({
  queryKey,
}: any): Promise<LeadDetailResponse> => {
  const result = await api.get(`/leads/${queryKey[1]}`)

  return result.data
}

export const addAOIToLead = (leadId: number, locations: Location) => {
  return api.post('/leads/aoi/update', {
    leadId: leadId,
    ...locations,
  })
}

export const deleteLeadSavedSearch = async (
  leadId: number,
  searchId: number,
) => {
  const result = await api.delete(`/leads/${leadId}/saved-search/${searchId}`)

  return result.data
}

export const updateLeadSavedSearch = async (
  leadId: number,
  searchId: number,
  payload: any,
) => {
  const result = await api.post(
    `/leads/${leadId}/saved-search/${searchId}`,
    payload,
  )

  return result.data
}

export const getSavedSearchUri = async (leadId: number, searchId: number) => {
  const result = await api.get(`/leads/${leadId}/saved-search-uri/${searchId}`)

  return result.data
}

export const createLead = async (lead: CreateLeadPayload) => {
  const result = await api.post('/leads/create', lead)

  return result.data
}
