export enum TENANT {
  VIEW = 'Management.Tenants.VIEW',
  UPDATE = 'Management.Tenants.UPDATE',
}

type Tenants = {
  VIEW: TENANT.VIEW
  UPDATE: TENANT.UPDATE
}

export enum MEMBER {
  VIEW = 'Management.Members.VIEW',
  CREATE = 'Management.Members.CREATE',
  DELETE = 'Management.Members.DELETE',
  UPDATE = 'Management.Members.UPDATE',
  ALL = 'Management.Members.ALL',
  GROUPS = 'Management.Members.GROUPS',
  ASSIGN_TO_GROUP = 'Management.Members.ASSIGN_GROUP',
  UNASSIGN_FROM_GROUP = 'Management.Members.UNASSIGN_FROM_GROUP',
  SITES = 'Management.Members.SITES',
}

type Members = {
  VIEW: MEMBER.VIEW
  CREATE: MEMBER.CREATE
  DELETE: MEMBER.DELETE
  UPDATE: MEMBER.UPDATE
  ALL: MEMBER.ALL
  GROUPS: MEMBER.GROUPS
  ASSIGN_TO_GROUP: MEMBER.ASSIGN_TO_GROUP
  UNASSIGN_FROM_GROUP: MEMBER.UNASSIGN_FROM_GROUP
  SITES: MEMBER.SITES
}

export enum ONBOARD {
  CREATE_WIZARD = 'Management.Onboard.CREATE_WIZARD',
  CREATE_NEW_BRAND = 'Mangement.Onboard.CREATE_NEW_BRAND',
}
type Onboard = {
  CREATE_WIZARD: ONBOARD.CREATE_WIZARD
  CREATE_NEW_BRAND: ONBOARD.CREATE_NEW_BRAND
}

export enum BRANDS {
  VIEW = 'Management.Brands.VIEW',
  CREATE = 'Management.Brands.CREATE',
  DELETE = 'Management.Brands.DELETE',
  UPDATE = 'Management.Brands.UPDATE',
  ZOHO_SYNC = 'Management.Brands.ZOHO_SYNC',
  ALL = 'Management.Brands.ALL',
  LOCATION_ADD = 'Management.Brands.LOCATION_ADD',
  LOCATION_REMOVE = 'Management.Brands.LOCATION_REMOVE',
  LOCATION_ASSIGNED = 'Management.Brands.LOCATION_ASSIGNED',
}

type Brands = {
  VIEW: BRANDS.VIEW
  CREATE: BRANDS.CREATE
  DELETE: BRANDS.DELETE
  UPDATE: BRANDS.UPDATE
  ZOHO_SYNC: BRANDS.ZOHO_SYNC
  ALL: BRANDS.ALL
  LOCATION_ADD: BRANDS.LOCATION_ADD
  LOCATION_REMOVE: BRANDS.LOCATION_REMOVE
  LOCATION_ASSIGNED: BRANDS.LOCATION_ASSIGNED
}

export enum GROUPS {
  VIEW = 'Management.Groups.VIEW',
  CREATE = 'Management.Groups.CREATE',
  DELETE = 'Management.Groups.DELETE',
  UPDATE = 'Management.Groups.UPDATE',
  ALL = 'Management.Groups.ALL',
  AVAILABLE = 'Management.Groups.AVAILABLE',
  IN_BRAND = 'Management.Groups.IN_BRAND',
  ASSIGN_LOCATION = 'Management.Groups.ASSIGN_LOCATION',
  UNASSIGN_LOCATION = 'Management.Groups.UNASSIGN_LOCATION',
  LOCATION_ASSIGNED = 'Management.Groups.LOCATION_ASSIGNED',
  SEND_TEST_LEAD = 'Management.Groups.SEND_TEST_LEAD',
  PING_STATS = 'Management.Groups.PING_STATS',
  PING_ZIPS = 'Management.Groups.PING_ZIPS',
  DELETE_PING_ZIP = 'Management.Groups.DELETE_PING_ZIP',
  RESET_PING_ZIP = 'Management.Groups.RESET_PING_ZIP',
}
type Groups = {
  VIEW: GROUPS.VIEW
  CREATE: GROUPS.CREATE
  DELETE: GROUPS.DELETE
  UPDATE: GROUPS.UPDATE
  ALL: GROUPS.ALL
  AVAILABLE: GROUPS.AVAILABLE
  IN_BRAND: GROUPS.IN_BRAND
  ASSIGN_LOCATION: GROUPS.ASSIGN_LOCATION
  UNASSIGN_LOCATION: GROUPS.UNASSIGN_LOCATION
  LOCATION_ASSIGNED: GROUPS.LOCATION_ASSIGNED
  SEND_TEST_LEAD: GROUPS.SEND_TEST_LEAD
  PING_ZIP: GROUPS.PING_ZIPS
  PING_STATS: GROUPS.PING_STATS
  DELETE_PING_ZIP: GROUPS.DELETE_PING_ZIP
  RESET_PING_ZIP: GROUPS.RESET_PING_ZIP
}

export enum CAMPAIGNS {
  CREATE_ASSIGNMENT = 'Management.Campaigns.CREATE_ASSIGNMENT',
  UPDATE_ASSIGNMENT = 'Management.Campaigns.UPDATE_ASSIGNMENT',
  ASSIGNED = 'Management.Campaigns.ASSIGNED',
  DELETE_ASSIGNED = 'Management.Campaigns.DELETE_ASSIGNED',
  ALL = 'Management.Campaigns.ALL',
}

type Campaigns = {
  CREATE_ASSIGNMENT: CAMPAIGNS.CREATE_ASSIGNMENT
  UPDATE_ASSIGNMENT: CAMPAIGNS.UPDATE_ASSIGNMENT
  ASSIGNED: CAMPAIGNS.ASSIGNED
  DELETE_ASSIGNED: CAMPAIGNS.DELETE_ASSIGNED
  ALL: CAMPAIGNS.ALL
}

export enum ROLES {
  CREATE = 'Management.Roles.CREATE',
  UPDATE = 'Management.Roles.UPDATE',
  DELETE = 'Management.Roles.DELETE',
  LIST = 'Management.Roles.LIST',
  IS_SUPER_ADMIN = 'Management.Roles.IS_SUPER_ADMIN',
  IS_BRAND_ADMIN = 'Management.Roles.IS_BRAND_ADMIN',
}

type Roles = {
  CREATE: ROLES.CREATE
  UPDATE: ROLES.UPDATE
  DELETE: ROLES.DELETE
  LIST: ROLES.LIST
  IS_SUPER_ADMIN: ROLES.IS_SUPER_ADMIN
  IS_BRAND_ADMIN: ROLES.IS_BRAND_ADMIN
}

// TODO: add MLS FEEDS

export interface Management {
  Members: Members
  Onboard: Onboard
  Brands: Brands
  Groups: Groups
  Campaigns: Campaigns
  Tenants: Tenants
  Roles: Roles
}
