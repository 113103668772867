import 'simplebar/dist/simplebar.min.css'

import { forwardRef } from 'react'
import SimpleBar from 'simplebar-react'

import { styled } from '@mui/material/styles'

import type { MutableRefObject } from 'react'
import type { Theme } from '@mui/material'
import type { SxProps } from '@mui/system'

interface ScrollbarProps extends SimpleBar.Props {
  ref: MutableRefObject<SimpleBar>
  sx?: SxProps<Theme>
}

const ScrollbarRoot = styled(SimpleBar)``

export const Scrollbar = forwardRef<
  MutableRefObject<SimpleBar>,
  ScrollbarProps
>((props, ref1) => {
  return <ScrollbarRoot ref={ref1} {...props} />
})
