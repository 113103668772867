export const setByDotNotation = (
  obj: Record<string, any>,
  path: string,
  defaultValue: any = undefined,
): any => {
  if (typeof path !== 'string') {
    path = String(path)
    if (typeof path !== 'string') {
      return defaultValue
    }
  }

  try {
    return path
      .split('.')
      .reduce((result: any, key: string) => result?.[key] || defaultValue, obj)
  } catch (error) {
    return defaultValue
  }
}
