export enum SETTINGS {
  ACCESS = 'General.Settings.ACCESS',
}

type Settings = {
  ACCESS: SETTINGS.ACCESS
}

export interface Global {
  Settings: Settings
}
