export const unflattenObject = (inputObject: {}) => {
  // Initialize an empty object to store the result
  const resultObject = {}

  // Iterate over each property in the input object
  for (const property in inputObject) {
    // Check if the property is a direct property of the object and not from the prototype chain
    if (Object.prototype.hasOwnProperty.call(inputObject, property)) {
      // Split the property name into keys using dot as separator
      const keys: any = property.split('.')

      // Use the reduce function to create nested objects for each key
      keys.reduce((currentObject, currentKey, index) => {
        // If this key already exists in the current object, return its value
        if (currentObject[currentKey]) {
          return currentObject[currentKey]
        }

        // Check if the next key is a number
        const isNextKeyNumber = !isNaN(keys[index + 1])

        // If this is the last key, use the value from the input object
        // Otherwise, create a new object or array based on whether the next key is a number
        return (currentObject[currentKey] = isNextKeyNumber
          ? []
          : keys.length - 1 === index
          ? inputObject[property]
          : {})
      }, resultObject)
    }
  }

  // Return the result object
  return resultObject
}
